import React from "react";
import { Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { memo } from 'react';
import { FormGenerator } from './formGenerator';
import { CombineFieldInterface, FormSelectField, FormPasswordInputField, FormInputField } from './utils';
import * as s from '../style';

export { CombineFieldInterface };

interface Props {
    onSubmit?: (values: any, resetForm: () => void) => void | Promise<void>;
    fields: CombineFieldInterface[];
    onChange?: (props: FormikProps<{ form: any }>) => void | Promise<void>;
    initialValues?: object | null;
    handleValidate?: (values: { form: any }) => ({ form?: any });
    action?: (formData: FormData) => void | Promise<void>
    validateOnBlur?: boolean
    enableReinitialize?: boolean
}

function _Form({ onSubmit, fields, onChange, validateOnBlur = true, initialValues, handleValidate, action, enableReinitialize }: Props) {
    const formGenerator = new FormGenerator(fields);

    /* Removing unknown fields from initialValues which are not meniton in the (fields: CombineFieldInterface[]) */
    initialValues = Object.keys(initialValues || {}).length
        ? formGenerator.removeUnknownFields(structuredClone(initialValues))
        // ? formGenerator.removeUnknownFields(JSON.parse(JSON.stringify(initialValues)))
        : undefined;

    const handleSubmit = async (values: { form: object }, { setSubmitting, resetForm, setValues }: FormikHelpers<{ form: object }>) => {
        // console.log(values)
        const handleReset = () => { resetForm({ values: formGenerator.getInitialValues() }) };
        if (action) {
            const formData = new FormData();
            formData.append("formValues", JSON.stringify(values.form))
            await action(formData);
        }
        await onSubmit?.(values.form, handleReset);
        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={
                Object.keys(initialValues || {}).length
                    ? { form: initialValues }
                    : (formGenerator.getInitialValues() as any)
            }
            validationSchema={formGenerator.getValidationSchema()}
            onSubmit={handleSubmit}
            validate={handleValidate}
            validateOnBlur={validateOnBlur}
            enableReinitialize={enableReinitialize}
        >
            {(formikProps) => {
                const { values, errors } = formikProps;
                console.log("errors ==>", errors)
                console.log("values ==>", values)
                onChange?.(formikProps);
                formGenerator.setFormikProps(formikProps);
                return (
                    <Grid container spacing={1} component={Form} style={s.formStyle()}>
                        {formGenerator.getFormFieldsJsx()}
                    </Grid>
                );
            }}
        </Formik>
    );
}

export default memo(_Form);

export { FormSelectField, FormPasswordInputField, FormInputField }