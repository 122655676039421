import React, { FC, useEffect, useState } from 'react';
import Form, { CombineFieldInterface } from './FormLib';
import { TextField, } from 'formik-material-ui';
import { Typography, Grid, Checkbox, CircularProgress, InputAdornment, Box, Step, StepLabel, Stepper } from '@material-ui/core';
// import { AccountCircle, EuroSymbol, Business, LocalAtm, Description, Person } from '@material-ui/icons';
import * as s from './style';
// import { countries } from './countries.json';
import { StyledBtn } from '../Button';
// import * as yup from 'yup';
// import { schemaObject, formInitialValues as formFieldValues, field as userDetailField } from './UserDetails';
// import { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import Alert, { SuccessDialog, ErrorDialog } from '../Alert';
import { applyQardanHasana } from './form';
import { applyQardanHasana as applyQardanHasanaFormApi } from '../../services/apply-qardan-hasana';
import { GenerateFormDefaultValues, GenerateFormSchema, GenerateFrom, setDataOnObjectPath } from './utils';
import { setValueInObjectField } from '../../helper';
import { Link, navigate } from 'gatsby';
import { pages } from '../../../app-config.json';
import { filterNumber } from './UserDetails';
import { AccountCircle, Business, Description, Person } from '@material-ui/icons';
import { countries } from './countries.json';
import { useField } from 'formik';
import { useAppDispatch, useAppSelector } from '../../reduxStore/store';
import { setActiveStep, resetForm, markFormCompleted, loadFormState, submitFormData, validateFormDataBeforeSubmission } from '../../reduxStore/slices/PersonalQardanFormSlice';
import moment from 'moment';
import styled from 'styled-components';
import { screenWidths } from '../../theme/css-generators';


const StyledStepLabel = styled(StepLabel) <{ selected?: boolean }>`
& > * {
    padding: 0px !important;
    & > * {
        outline: ${({ selected }) => selected ? '6' : '0'}px solid #edbb4a;
        border-radius: 30px;
        height: 40px !important;
        width: 40px !important;
        @media (max-width: ${screenWidths._500px}){
            outline: ${({ selected }) => selected ? '3' : '0'}px solid #edbb4a;
            height: 25px !important;
            width: 25px !important;        
        }
    }
}
`

//////////////////////////////////  component ////////////////////////////////
const PersonalQardanHasana: FC<{}> = () => {
    const { activeStep, formSteps } = useAppSelector(s => s.PersonalQardanForm);
    const dispatch = useAppDispatch();

    console.log("formSteps ==>", formSteps)

    useEffect(() => {
        console.log("<==== loadFormState ===>")
        dispatch(loadFormState());
    }, [])

    const ActiveForm = ([Step1, Step2, Step3, Step4, Step5, Step6, Step7])[activeStep - 1];

    return (
        <>
            <Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep - 1} orientation="horizontal"  >
                {formSteps.map(({ step, completed }) => {
                    return (
                        <Step style={{ padding: '0', cursor: 'pointer' }} completed={completed} key={step} onClick={() => dispatch(setActiveStep({ step }))} >
                            <StyledStepLabel selected={step === activeStep} />
                        </Step>
                    );
                })}
            </Stepper >

            <ActiveForm step={activeStep} />
        </>
    )
}

export default PersonalQardanHasana;

const fileError = "Please upload a file less than 10 MB";
function checkFileSizeAndSetError(errorObj, keyPath, files: Blob[]) {
    files?.forEach(file => {
        // console.log("file.size ==>", file.name, file.size)
        if (file instanceof Blob && file.size > 10 /* MB */ * 1000 /* KB */ * 1000/* bytes */)
            setDataOnObjectPath(errorObj, keyPath, fileError)
    })
}

////////////////////////////////// Forms by step ////////////////////////////////

function Step1({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const dispatch = useAppDispatch();

    console.log(values)

    return (
        <Form
            // onChange={({ values }) => console.log(JSON.stringify(values))}
            onSubmit={values => {
                console.log(console.log(values))
                dispatch(markFormCompleted({ step: _step, values }));
            }}
            handleValidate={({ form }) => {
                const errors = {};
                checkFileSizeAndSetError(errors, 'personal.applicant_photo', form.personal?.applicant_photo || [])
                checkFileSizeAndSetError(errors, 'personal.applicant_passport', form.personal?.applicant_passport || [])
                checkFileSizeAndSetError(errors, 'personal.property_details.proof_of_address', form.personal.property_details?.proof_of_address || [])
                checkFileSizeAndSetError(errors, 'personal.residential_status_in_uk.biometric_residence_permit', form.personal.residential_status_in_uk?.biometric_residence_permit || [])
                return Object.keys(errors).length > 0 ? { form: errors } : {};
            }}
            initialValues={values}
            enableReinitialize
            fields={[
                {
                    type: 'fieldGroup',
                    name: "personal", fieldArea: 12,
                    root: true,
                    // labelIcon: () => <span><AccountCircle fontSize='large' /></span>,
                    label: <s.LabelWrap>
                        <span><AccountCircle fontSize='large' /></span>
                        <Typography variant='h5' align='center' >Personal Details</Typography>
                        {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ gap: '5px' }}><AccountCircle fontSize='large' />Personal Details</Box>, */}
                    </s.LabelWrap>,
                    fieldGroup: [
                        { type: "date", name: 'date_of_application', label: 'Date Of Application', value: moment().format("DD-MM-yyyy"), notRequired: true, condition: { fieldName: "no-field", value: [''] } },
                        {
                            type: "fieldGroup", fieldArea: 12,
                            name: "name",
                            // label: "Name",
                            fieldGroup: [
                                { name: "title", type: "select", label: 'Title', fieldArea: { xs: 12, sm: 6, md: 6, lg: 3 }, options: ["Bhai", "Behen", "Shaikh", "Mulla"] },
                                { name: "first_name", type: "text", label: 'First Name', fieldArea: { xs: 12, sm: 6, md: 6, lg: 3 } },
                                { name: "middle_name", type: "text", label: 'Middle Name', notRequired: true, fieldArea: { xs: 12, sm: 6, md: 6, lg: 3 } },
                                { name: "last_name", type: "text", label: 'Last Name', fieldArea: { xs: 12, sm: 6, md: 6, lg: 3 } },
                            ]
                        },
                        {
                            type: "fieldGroup", fieldArea: 12,
                            // label: "DemoGraph",
                            name: 'demographic',
                            fieldGroup: [
                                { type: "text", name: 'its_no', label: 'ITS number', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "date", name: 'date_of_birth', label: 'Date Of Birth', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "select", name: 'marital_status', label: 'Marital Status', options: ["Single", "Married", "Widower", "Divorced"], fieldArea: { xs: 12, sm: 6, md: 4 } },
                            ]
                        },
                        { type: "file", name: 'applicant_photo', label: 'Applicant Photo', accept: ".png, .jpg, .jpeg", description: <>Upload a passport size photo wearing a topi / rida <br /> Accepted file types: jpg, jpeg, png, Max. file size: 2 MB</>, fieldArea: { xs: 12, md: 6 }, },
                        { type: "file", name: 'applicant_passport', label: 'Applicant Passport', accept: ".png, .jpg, .jpeg, .pdf, gif", description: <>Upload a copy of your passport <br /> Accepted file types: jpg, jpeg, gif, png, pdf, Max. file size: 2 MB</>, fieldArea: { xs: 12, md: 6 }, },
                        { type: "text-note", name: 'separator-01', label: '', fieldArea: 12, element: <Box my='10px' /> },
                        {
                            type: "fieldGroup", fieldArea: 12, name: 'contact',
                            fieldGroup: [
                                { type: "email", name: 'email', label: 'Email', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "tel", name: 'whatsapp', label: 'WhatsApp', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "tel", name: 'mobile', label: 'Mobile', fieldArea: { xs: 12, sm: 6, md: 4 } },
                            ]
                        },
                        {
                            type: "fieldGroup", fieldArea: 12, name: "address",
                            fieldGroup: [
                                { type: "text", name: 'street_address', label: 'Street Address', fieldArea: 12 },
                                { type: "text", name: 'city', label: 'City', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), fieldArea: { xs: 12, sm: 6, md: 4 }, value: 'United Kingdom' },
                                { type: "text", name: 'post_code', label: 'ZIP / Postal Code', fieldArea: { xs: 12, sm: 6, md: 4 } },
                            ]
                        },
                        {
                            type: "fieldGroup", fieldArea: 12, name: "property_details",
                            fieldGroup: [
                                { type: "radio", name: 'property_status', label: 'Property Status (of above property)', options: ["Owner", "Rented", "Living With Parents"], fieldArea: { xs: 12 }, },
                                { type: "file", name: 'proof_of_address', label: 'Proof of Address (of above property)', accept: ['jpg', 'png', 'pdf', 'jpeg'], description: <>E.g. Utility bill, Driving License, etc</>, fieldArea: { xs: 12 } },
                                { type: "radio", name: 'have_you_stayed_for_at_least_3_years', label: 'Have you stayed at this address for at least 3 years?', options: ["Yes", "No"], fieldArea: { xs: 12 } },
                                {
                                    type: "text-area", name: 'last_3_years_address', label: 'Address(es) for past 3 year', fieldArea: { xs: 12 },
                                    condition: { fieldName: "personal.property_details.have_you_stayed_for_at_least_3_years", value: ["No"] }
                                },
                            ]
                        },
                        {
                            type: "fieldGroup", fieldArea: 12, name: "residential_status_in_uk",
                            fieldGroup: [
                                { type: "radio", name: 'property_status', label: 'Residential Status in the UK', options: ["British Citizen", "Other"], fieldArea: { xs: 12 }, },
                                {
                                    type: "radio", name: 'visa_status', label: 'Visa Status', fieldArea: { xs: 12 },
                                    options: ["Indefinite Leave to Remain", "Student Visa", "Other"],
                                    condition: { fieldName: "personal.residential_status_in_uk.property_status", value: ["Other"] }
                                },
                                {
                                    type: 'text-note', name: "warning", label: "",
                                    element: <Typography style={{ color: 'red', marginTop: "-15px", marginBottom: '10px' }} variant='body2'>Any application with a BRP other than Student Visa or ILR uploaded risks outright rejection. Therefore, we request that the applicants get in touch with Qardan Hasana Office at Anjuman-e-Burhani (London) by emailing at <a href={`mailto:${pages.CONTACT.details.email}`}>{pages.CONTACT.details.email}</a></Typography>,
                                    condition: { fieldName: "personal.residential_status_in_uk.visa_status", value: ["Other"] }
                                },
                                {
                                    type: "file", name: 'biometric_residence_permit', label: 'Biometric Residence Permit', accept: ['jpg', 'png', 'pdf', 'jpeg'], fieldArea: { xs: 12 },
                                    condition: { fieldName: "personal.residential_status_in_uk.property_status", value: ["Other"] }
                                },
                            ]
                        },
                    ],
                },
                ...FormButtons(),
            ]}
        />
    )
}

function Step2({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const dispatch = useAppDispatch();
    return (
        <Form
            initialValues={values}
            enableReinitialize
            onSubmit={values => {
                console.log(console.log(values))
                dispatch(markFormCompleted({ step: _step, values }));
            }}
            handleValidate={({ form }) => {
                const errors = {};
                checkFileSizeAndSetError(errors, 'deeni_expenses.safai_chitti', form.deeni_expenses?.safai_chitti || [])
                return Object.keys(errors).length > 0 ? { form: errors } : {};
            }}
            fields={[
                {
                    type: "fieldGroup", fieldArea: 12, name: "deeni_expenses",
                    root: true,
                    label: <s.LabelWrap>
                        <span style={{ fontSize: "30px" }}><b>£</b></span>
                        <Typography variant='h5' align='center' >Deeni Expenses</Typography>
                        {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ gap: '5px' }}><AccountCircle fontSize='large' />Personal Details</Box>, */}
                    </s.LabelWrap>,
                    fieldGroup: [
                        { type: 'radio', name: 'offered_charitable_annual', label: "Offered Charitable Contributions (Annual)", options: ["Yes", "No"], fieldArea: 12 },
                        {
                            type: 'number', name: 'amount_offered_charitable_annual', label: "Last Amount Offered As Charitable Contributions (Annual)", fieldArea: 12, notRequired: true,
                            condition: { fieldName: "deeni_expenses.offered_charitable_annual", value: ["Yes"] },
                            inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }
                        },
                        { type: 'radio', name: 'offered_charitable_anjuman', label: "Offered Charitable Contributions (Anjuman)", options: ["Yes", "No"], fieldArea: 12, },
                        {
                            type: 'number', name: 'amount_offered_charitable_anjuman', label: "Last Amount Offered As Charitable Contributions (Anjuman)", fieldArea: 12, notRequired: true,
                            condition: { fieldName: "deeni_expenses.offered_charitable_anjuman", value: ["Yes"] },
                            inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }
                        },
                        {
                            type: 'custom', name: 'avg_monthly_total', label: 'Average Monthly Total', notRequired: true,
                            CustomField: ({ scope, label, name }) => {
                                const [field, meta, helper] = useField(name);
                                const { amount_offered_charitable_annual = 0, amount_offered_charitable_anjuman = 0 } = scope.formikProps.values?.form?.deeni_expenses || {};
                                const amount = ((amount_offered_charitable_annual / 12) + (amount_offered_charitable_anjuman / 12));
                                useEffect(() => { helper.setValue(isNaN(amount) ? 0 : amount === Infinity ? 0 : amount) }, [amount])
                                return <div style={{ margin: '10px 0', }} >
                                    <Typography>{label}</Typography>
                                    <Typography>£ {amount.toFixed(2)}</Typography>
                                </div>
                            }
                        },
                        { type: 'file', name: 'safai_chitti', label: "Safai Chitti", accept: ['jpg', 'png', 'pdf', 'jpeg'], fieldArea: 12, },
                    ]

                },
                ...FormButtons()
            ]}
        />
    )
}

function Step3({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const dispatch = useAppDispatch();
    const mainCondition = { fieldName: "professional_details.employment_status", value: ["Employed Full Time", 'Employed Part Time', 'Employed Temporary Contract', 'Self Employed', 'Sole Trader'] };
    return (
        <Form
            initialValues={values}
            enableReinitialize
            onSubmit={values => {
                console.log(console.log(JSON.stringify(values)))
                dispatch(markFormCompleted({ step: _step, values }));
            }}
            fields={[
                {
                    type: "fieldGroup", fieldArea: 12, name: "professional_details",
                    root: true,
                    label: <s.LabelWrap>
                        <span><Business fontSize='large' /></span>
                        <Typography variant='h5' align='center' >Professional Details</Typography>
                        {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ gap: '5px' }}><AccountCircle fontSize='large' />Personal Details</Box>, */}
                    </s.LabelWrap>,
                    fieldGroup: [
                        { type: "select", name: "employment_status", label: "Employment Status", options: ["Employed Full Time", 'Employed Part Time', 'Employed Temporary Contract', 'Self Employed', 'Retired', 'Student', 'Sole Trader', 'Other'], fieldArea: 12 },

                        {
                            type: "fieldGroup", name: "org_details", fieldArea: 12,
                            condition: mainCondition,
                            fieldGroup: [
                                { type: 'text', name: "job_title", label: "Job Title", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: mainCondition, },
                                { type: 'text', name: "org_name", label: "Organization Name", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: mainCondition, },
                                { type: 'date', name: "joining_date", label: "Joining Date", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: mainCondition, },
                                { type: 'text', name: "org_address", label: "Organization Address", fieldArea: { sm: 12 }, condition: mainCondition, },
                                { type: 'text', name: "city", label: "City", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: mainCondition, },
                                { type: 'text', name: "post_code", label: "Post Code", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: mainCondition, },
                                { type: 'text', name: "country", label: "Country", value: "United Kingdom", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: mainCondition, },
                            ],
                        },
                        {
                            type: "text-area", name: "details", label: "Details", fieldArea: 12,
                            condition: { fieldName: "professional_details.employment_status", value: ['Other'] }
                        },
                        { type: "radio", name: "is_this_your_last_3_years_status", label: "Has this been your employment status/organisation for the past 3 years?", options: ["Yes", 'No'], fieldArea: 12 },
                        {
                            type: "text-area", name: "details_of_past_employment", label: "Employment status/organisation for past 3 years", fieldArea: 12,
                            condition: { fieldName: "professional_details.is_this_your_last_3_years_status", value: ['No'] }
                        },

                    ]
                },
                ...FormButtons()
            ]}
        />
    )
}

function Step4({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const dispatch = useAppDispatch();
    const startAdornment = <InputAdornment position="start">£</InputAdornment>
    const EducationCondition = { fieldName: "qardan_hasana_details.purpose", value: ["Education"] }
    return (
        <Form
            initialValues={values}
            enableReinitialize
            handleValidate={({ form }) => {
                console.log("Log in handleValidate ==>", form)
                const errors = {};
                if (form?.qardan_hasana_details?.purpose === "Housing" &&
                    !form?.qardan_hasana_details?.property_details.property_docs?.[0]
                ) {
                    errors["qardan_hasana_details"] = {
                        property_details: { property_docs: "Required" }
                    }
                }
                if (form?.qardan_hasana_details?.purpose === "Education" &&
                    !form?.qardan_hasana_details?.education_details.educational_docs?.[0]
                ) {
                    errors["qardan_hasana_details"] = {
                        education_details: { educational_docs: "Required" }
                    }
                }

                checkFileSizeAndSetError(errors, 'qardan_hasana_details.property_details.property_docs', form?.qardan_hasana_details?.property_details?.property_docs || [])
                checkFileSizeAndSetError(errors, 'qardan_hasana_details.education_details.educational_docs', form?.qardan_hasana_details?.education_details?.educational_docs || [])

                return Object.keys(errors).length ? { form: errors } : {}
            }}
            onSubmit={values => {
                console.log(console.log(JSON.stringify(values)))
                dispatch(markFormCompleted({ step: _step, values }));
            }}
            fields={[
                {
                    type: "fieldGroup", fieldArea: 12, name: "qardan_hasana_details",
                    root: true,
                    label: <s.LabelWrap>
                        <span style={{ fontSize: "30px" }} ><b>£</b></span>
                        <Typography variant='h5' align='center' >Qardan Hasana Details</Typography>
                        {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ gap: '5px' }}><AccountCircle fontSize='large' />Personal Details</Box>, */}
                    </s.LabelWrap>,
                    fieldGroup: [
                        { type: "select", name: "purpose", label: "Purpose for Qardan Hasana", options: ["Personal", "Housing", "Education", "Charity"], fieldArea: 12 },
                        { type: "text-area", name: "additional_details", label: "Please provide additional details", fieldArea: 12, },

                        ////////////////////  Qardan detailsn for "Personal", "Housing" & "Charity" /////////////
                        { type: "number", name: "qardan_amount", label: "Qardan Hasana Amount Required", fieldArea: { xs: 12, sm: 6, md: 4 }, inputProps: { startAdornment }, condition: { fieldName: "qardan_hasana_details.purpose", value: ["Personal", "Housing", "Charity"] } },
                        { type: "number", name: "project_cost", label: "Total Project Cost", fieldArea: { xs: 12, sm: 6, md: 4 }, inputProps: { startAdornment }, condition: { fieldName: "qardan_hasana_details.purpose", value: ["Personal", "Housing", "Charity"] } },
                        { type: "text", name: "remaining_amount", label: "Remaining amounts to be obtained from", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: { fieldName: "qardan_hasana_details.purpose", value: ["Personal", "Housing", "Charity"] } },
                        { type: "number", name: "qardan_duration", label: "Duration of Qardan Hasana (Months)", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: { fieldName: "qardan_hasana_details.purpose", value: ["Personal", "Housing", "Charity"] } },
                        {
                            type: "custom", name: "monthly_repayment_amount", label: "Monthly Repayment Amount", fieldArea: { xs: 12, sm: 6, md: 4 },
                            CustomField: ({ scope, label, name, condition }) => {
                                const { qardan_amount = 0, qardan_duration = 0 } = scope.formikProps.values?.form?.qardan_hasana_details || {};
                                const repayment = qardan_amount / qardan_duration;
                                const [field, meta, helper] = useField(name);
                                useEffect(() => { helper.setValue(isNaN(repayment) ? 0 : repayment === Infinity ? 0 : repayment) }, [repayment])
                                return <div style={{ margin: '10px 0', }} >
                                    <Typography>{label}</Typography>
                                    <Typography>£ {field.value?.toFixed?.(2)}</Typography>
                                </div>
                            },
                            condition: { fieldName: "qardan_hasana_details.purpose", value: ["Personal", "Housing", "Charity"] }
                        },

                        //////////////////// Housing details /////////////
                        {
                            type: 'fieldGroup', name: "property_details", label: <Box mb='5px' >Details of the property for which you are requesting Qardan Hasana</Box>, fieldArea: 12,
                            condition: { fieldName: "qardan_hasana_details.purpose", value: ["Housing"] },
                            fieldGroup: [
                                { type: "select", name: "property_type", label: "Property Type", fieldArea: { xs: 12, sm: 6, md: 4 }, options: ["New", "Renovation", "Shared"], condition: { fieldName: "qardan_hasana_details.purpose", value: ["Housing"] }, },
                                { type: "text", name: "no_of_bedrooms", label: "No. of Bedrooms", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: { fieldName: "qardan_hasana_details.purpose", value: ["Housing"] }, },
                                { type: "number", name: "property_value", label: "Property Value", fieldArea: { xs: 12, sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: { fieldName: "qardan_hasana_details.purpose", value: ["Housing"] }, },
                                { type: "file", name: "property_docs", label: "Property Documents", fieldArea: { xs: 12, sm: 6, md: 4 }, accept: ['png', 'pdf', 'jpg', 'jpeg', 'docx', 'doc', 'zip'], description: "E.g. Deeds, Valuation, Floor plan, etc.", condition: { fieldName: "qardan_hasana_details.purpose", value: ["Housing"] }, },
                            ]
                        },

                        //////////////////// Education details /////////////
                        {
                            type: 'fieldGroup', name: "education_details", label: <Typography variant='h5' style={{ marginBottom: '15px' }} >Education Details</Typography>, fieldArea: 12,
                            condition: EducationCondition,
                            fieldGroup: [
                                { type: "radio", name: "attend_madrasah", label: "Have you attended Madrasah?", fieldArea: { xs: 12, md: 6 }, options: ["Yes", "No"], condition: EducationCondition },
                                {
                                    type: "select", name: "madrasah_level", label: "Madrasah level completed", options: ["KG", ...Array(10).fill(1).map((_, idx) => `Year ${idx + 1}`)], fieldArea: { xs: 12, md: 6 },
                                    condition: { fieldName: "qardan_hasana_details.education_details.attend_madrasah", value: ["Yes"] },
                                },
                                { type: "text", name: "university_name", label: "Name of university which you are planning to attend", fieldArea: { xs: 12, md: 6 }, condition: EducationCondition },
                                { type: "text", name: "degree_name", label: "Degree / Diploma that you are pursuing", fieldArea: { xs: 12, md: 6 }, condition: EducationCondition },

                                { type: "text", name: "address", label: "University Address", fieldArea: { xs: 12 }, condition: EducationCondition },
                                { type: "text", name: "city", label: "City", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition },
                                { type: "text", name: "post_code", label: "Post Code", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition },
                                { type: "select", name: "country", label: "Country", options: countries.map(v => v.name), fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition },

                                { type: "date", name: "start_date", label: "Start Data", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition },
                                { type: "date", name: "end_date", label: "Graduation Date", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition },
                                { type: "radio", name: "has_raza_taken", label: "Have you taken Raza Mubarak for the course?", options: ["Yes", "No"], fieldArea: { xs: 12, md: 6 }, condition: EducationCondition },
                                { type: "radio", name: "will_stay_at_home", label: "Will you stay at home?", options: ["Yes", "No"], fieldArea: { xs: 12, md: 6 }, condition: EducationCondition },
                                { type: "radio", name: "have_applied_for_work_during_university", label: "Have you applied for work during university?", options: ["Yes", "No"], fieldArea: { xs: 12, md: 6 }, condition: EducationCondition },
                                { type: "file", name: "educational_docs", label: "Educational Documents", fieldArea: { xs: 12, sm: 6, md: 6 }, accept: ['png', 'pdf', 'jpg', 'jpeg', 'docx', 'doc', 'zip'], description: "E.g. Certificates, Admission letter, Fee structure, etc", condition: EducationCondition },
                                {
                                    type: "text-area", name: "details_of_work", label: "Details of your work during university", fieldArea: { xs: 12, md: 6 },
                                    condition: { fieldName: "qardan_hasana_details.education_details.have_applied_for_work_during_university", value: ["Yes"] },
                                },
                                {
                                    type: "text-area", name: "details_of_not_doing_work", label: "Reason for not working", fieldArea: { xs: 12, md: 6 },
                                    condition: { fieldName: "qardan_hasana_details.education_details.have_applied_for_work_during_university", value: ["No"] },
                                },
                                // { type: "date", name: "end_data", label: "Graduation Date", notRequired: true, fieldArea: { xs: 12, sm: 6, md: 4 }, },
                                // { type: "number", name: "property_value", label: "Property Value", notRequired: true, fieldArea: { xs: 12, sm: 6, md: 4 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> } },
                            ]
                        },
                        //////////////////// Education Finacial details /////////////
                        {
                            type: 'fieldGroup', name: "education_financial_details", label: <Typography variant='h5' style={{ marginBottom: '15px' }} >Financials</Typography>, fieldArea: 12,
                            condition: EducationCondition,
                            fieldGroup: [
                                { type: "number", name: "total_fees", label: "Total Fees", fieldArea: { xs: 12, md: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition },
                                { type: "number", name: "amount_saved", label: "Amount Saved", fieldArea: { xs: 12, md: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition },
                                { type: "number", name: "contribution_of_parents", label: "Contribution of Parents", fieldArea: { xs: 12, md: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition },
                                { type: "number", name: "amount_from_other_sources", label: "Amount From scholarships and other sources", fieldArea: { xs: 12, md: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition },
                                { type: "number", name: "qardan_required", label: "Qardan Hasana Amount Required", fieldArea: { xs: 12, md: 6 }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition },

                                {
                                    type: 'fieldGroup', name: "requirement_schedule", label: <Typography variant='h5' style={{ marginBottom: '15px' }} >Qardan Hasana Requirement Schedule</Typography>, fieldArea: 12,
                                    condition: EducationCondition,
                                    fieldGroup: [
                                        { type: "semi-number", name: "year_1", label: "Year 1", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_2", label: "Year 2", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_3", label: "Year 3", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_4", label: "Year 4", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_5", label: "Year 5", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        {
                                            type: "custom", name: "total_qardan", label: "Total Qardan Hasana Required", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition,
                                            CustomField: ({ scope, label, name, condition }) => {
                                                const { year_1 = 0, year_2 = 0, year_3 = 0, year_4 = 0, year_5 = 0 } = scope.formikProps.values?.form?.qardan_hasana_details?.education_financial_details?.requirement_schedule || {};
                                                const total = Number(year_1) + Number(year_2) + Number(year_3) + Number(year_4) + Number(year_5);
                                                const [field, meta, helper] = useField(name);
                                                useEffect(() => { helper.setValue(isNaN(total) ? 0 : total === Infinity ? 0 : total) }, [total])
                                                return <div style={{ margin: '10px 0', }} >
                                                    <Typography>{label}</Typography>
                                                    <Typography>£ {field?.value?.toFixed?.(2) || 0.00}</Typography>
                                                </div>
                                            },
                                        },
                                    ],
                                },

                                {
                                    type: 'fieldGroup', name: "repayment_schedule", label: <Typography variant='h5' style={{ marginBottom: '15px' }} >Qardan Hasana Repayment Schedule</Typography>, fieldArea: 12,
                                    condition: EducationCondition,
                                    fieldGroup: [
                                        { type: "semi-number", name: "year_1", label: "Year 1", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_2", label: "Year 2", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_3", label: "Year 3", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_4", label: "Year 4", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        { type: "semi-number", name: "year_5", label: "Year 5", fieldArea: { xs: 12, sm: 6, md: 4, }, inputProps: { startAdornment: <InputAdornment position="start">£</InputAdornment> }, condition: EducationCondition, },
                                        {
                                            type: "custom", name: "total_qardan", label: "Total Qardan Hasana Repaid", fieldArea: { xs: 12, sm: 6, md: 4 }, condition: EducationCondition,
                                            CustomField: ({ scope, label, name, condition }) => {
                                                const { year_1 = 0, year_2 = 0, year_3 = 0, year_4 = 0, year_5 = 0 } = scope.formikProps.values?.form?.qardan_hasana_details?.education_financial_details?.repayment_schedule || {};
                                                const total = Number(year_1) + Number(year_2) + Number(year_3) + Number(year_4) + Number(year_5);
                                                const [field, meta, helper] = useField(name);
                                                useEffect(() => { helper.setValue(isNaN(total) ? 0 : total === Infinity ? 0 : total) }, [total])
                                                return <div style={{ margin: '10px 0', }} >
                                                    <Typography>{label}</Typography>
                                                    <Typography>£ {field?.value?.toFixed?.(2) || 0.00}</Typography>
                                                </div>
                                            },
                                        },
                                    ],
                                },

                            ]
                        },
                        //////////////////// Charity details /////////////
                        {
                            type: 'text-area', label: "Charity Details", name: "details", fieldArea: 12,
                            condition: { fieldName: "qardan_hasana_details.purpose", value: ["Charity"] },
                        }

                    ]
                },
                ...FormButtons()
            ]}
        />
    )
}

function Step5({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const deeniExpenses = useAppSelector(s => s.PersonalQardanForm.formSteps[2 - 1].values.deeni_expenses);
    const dispatch = useAppDispatch();
    const startAdornment = <InputAdornment position="start">£</InputAdornment>
    return (
        <Form
            initialValues={values}
            enableReinitialize
            onSubmit={values => {
                console.log(values)
                dispatch(markFormCompleted({ step: _step, values }));
            }}
            handleValidate={({ form }) => {
                const errors = {};
                checkFileSizeAndSetError(errors, 'other_details.educational_details.certificate', form.other_details.educational_details?.certificate || [])
                checkFileSizeAndSetError(errors, 'other_details.monthly_income.income_document', form.other_details.monthly_income?.income_document || [])
                checkFileSizeAndSetError(errors, 'other_details.monthly_outgoings.outgoings_document', form.other_details.monthly_outgoings?.outgoings_document || [])
                return Object.keys(errors).length > 0 ? { form: errors } : {};
            }}
            fields={[
                {
                    type: "fieldGroup", fieldArea: 12, name: "other_details",
                    root: true,
                    label: <s.LabelWrap>
                        <span><Description fontSize='large' /></span>
                        <Typography variant='h5' align='center' >Other Details</Typography>
                    </s.LabelWrap>,
                    fieldGroup: [
                        {
                            type: "fieldGroup", label: "Educational Details", name: "educational_details", fieldArea: 12,
                            fieldGroup: [
                                { type: "text", name: "qualification", label: "Highest Educational Qualification", fieldArea: { xs: 12, sm: 6, md: 4 }, },
                                { type: "text", name: "institute_name", label: "Obtained from (Institution)", fieldArea: { xs: 12, sm: 6, md: 4 }, },
                                { type: "file", name: "certificate", label: "Certificate of highest educational qualification", accept: ['pdf', 'png', 'jpeg', 'jpg'], fieldArea: { xs: 12, sm: 6, md: 4 }, },
                            ]
                        },
                        {
                            type: "fieldGroup", label: "Monthly Income", name: "monthly_income", fieldArea: 12,
                            fieldGroup: [
                                {
                                    type: 'text-note', name: "note", fieldArea: 12, label: "",
                                    element: <Box fontSize='13px'>Please enter your <b>MONTHLY</b> incomes below. If you are receiving any income annually, then divide by twelve and enter the monthly figure.</Box>
                                },
                                { type: "semi-number", name: "income", label: "Income from being Self-employed / Business Profit", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "business_sales", label: "Business Sales", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "employment_income", label: "Employment Income", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "savings", label: "Savings", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "benefits", label: "Benefits", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "monthly_dividends", label: "Monthly Dividends", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "rental_income", label: "Rental Income", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "investment_income", label: "Investment Income", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "pension", label: "Pension", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                { type: "semi-number", name: "all_other_income", label: "All Other Income", inputProps: { startAdornment }, fieldArea: { xs: 12, sm: 6 }, },
                                // { type: "text", name: "total_monthly_income", label: "Total Monthly Income", fieldArea: { xs: 12, sm: 6 }, },
                                {
                                    type: "custom", name: "total_monthly_income", label: "Total Monthly Income", fieldArea: { xs: 12, sm: 6 },
                                    CustomField: ({ scope, label, name, condition }) => {
                                        const { income = 0, employment_income = 0, benefits = 0, monthly_dividends = 0, rental_income = 0, investment_income = 0, pension = 0, all_other_income = 0, } = scope.formikProps.values?.form?.other_details?.monthly_income || {};
                                        const repayment = Number(income) + Number(employment_income) + Number(benefits) + Number(monthly_dividends) + Number(rental_income) + Number(investment_income) + Number(pension) + Number(all_other_income);
                                        // console.log("repayment ==>", repayment, scope.formikProps.values?.form?.other_details);
                                        const [field, meta, helper] = useField(name);
                                        useEffect(() => { helper.setValue(isNaN(repayment) ? 0 : repayment === Infinity ? 0 : repayment) }, [repayment])
                                        return <div style={{ margin: '10px 0', }} >
                                            <Typography>{label}</Typography>
                                            <Typography>£ {field.value?.toFixed?.(2)}</Typography>
                                        </div>
                                    },
                                    // condition: { fieldName: "qardan_hasana_details.purpose", value: ["Personal", "Housing", "Charity"] }
                                },
                                { type: "file", name: "income_document", label: "Income Document", fieldArea: { xs: 12, sm: 6 }, accept: ['pdf', 'png', 'jpeg', 'jpg'] },

                                { type: "text-note", name: "line-seperator", label: "", fieldArea: { xs: 12 }, element: <hr /> },

                                { type: "radio", name: "do_you_expect_same_income_for_12_months", label: "Do you expect your income to remain at the same level for the next 12 months?", options: ['Yes', "No"], fieldArea: { xs: 12 } },
                                { type: "text-area", name: "reason_for_change_in_income_1", label: "Please provide details and reason for change in income", fieldArea: { xs: 12 }, condition: { fieldName: "other_details.monthly_income.do_you_expect_same_income_for_12_months", value: ['No'] } },

                                { type: "radio", name: "do_you_expect_change_in_income", label: "Do you know of any expected changes to your income for the term of Qardan Hasana applied for?", options: ['Yes', "No"], fieldArea: { xs: 12 } },
                                { type: "text-area", name: "reason_for_change_in_income_2", label: "Please provide details and reason for change in income", fieldArea: { xs: 12 }, condition: { fieldName: "other_details.monthly_income.do_you_expect_change_in_income", value: ['Yes'] } },

                                { type: "semi-number", name: "number_of_dependents", label: "How many people are dependent on your income?", fieldArea: { xs: 12, sm: 6 }, }
                            ]
                        },

                        {
                            type: 'fieldGroup', name: "outstanding_qardan", fieldArea: 12, label: <Typography gutterBottom>Outstanding Qardan Hasana</Typography>,
                            fieldGroup: [
                                { type: "radio", name: 'has_outstanding_qardan', label: "Do you have any Qardan Hasana outstanding?", options: ["Yes", "No"], fieldArea: { xs: 12, sm: 6 } },
                                { type: "text", name: 'organisation', label: "Organisation", fieldArea: { xs: 12, sm: 6 }, condition: { fieldName: "other_details.outstanding_qardan.has_outstanding_qardan", value: ["Yes"] } },
                                { type: "number", name: 'number_of_outstanding_qardan', label: "No. of instalments outstanding", fieldArea: { xs: 12, sm: 6 }, condition: { fieldName: "other_details.outstanding_qardan.has_outstanding_qardan", value: ["Yes"] } },
                                { type: "semi-number", name: 'amount_due_each_month', label: "Amount due for each instalment", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment }, condition: { fieldName: "other_details.outstanding_qardan.has_outstanding_qardan", value: ["Yes"] } },
                            ]
                        },

                        {
                            type: 'fieldGroup', name: 'monthly_outgoings', label: <Typography gutterBottom>Monthly Outgoings</Typography>, fieldArea: 12,
                            fieldGroup: [
                                {
                                    type: 'text-note', name: "note", label: "",
                                    element: <Box fontSize='13px'>
                                        Please enter your <b>MONTHLY</b> Outgoings below. If any outgoings are being made annually, then divide by twelve and enter the monthly figure.
                                        <div style={{ marginBottom: "5px" }}></div>
                                        If any of the below outgoings are being charged to your business and have been factored into the business income OR if they are being borne by your employer, then please leave them blank.
                                    </Box>
                                },
                                { type: "semi-number", name: "subscriptions", label: "Monthly Subscriptions (magazines, books, music, etc)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "phone_bills", label: "Mobile / Home Phone", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "tv_bill", label: "TV Subscriptions (including annual license)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "saloon", label: "Beauty / Hair", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "vacations", label: "Vacations", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "commiting_for_work", label: "Commuting to and from work", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "vehicle_maintenance", label: "Vehicle maintenance (tax, upkeep, fuel)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "dining_out", label: "Dining Out", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "groceries", label: "Groceries", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "gas", label: "Gas", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "electricity", label: "Electricity", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "water", label: "Water", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "internet", label: "Internet", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "fuel_for_household", label: "Fuel for Household (e.g. Oil based heating)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "council_tax", label: "Council Tax", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "pensions_contribution", label: "Pensions Contribution", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "house_rent", label: "House Rent", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "insurance", label: "Insurance (e.g. vehicle, commercial, professional indemnity, public liability, life, buildings and contents)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                { type: "semi-number", name: "medical", label: "Medical (e.g. private treatment, monthly prescriptions)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                // { type: "text", name: "outstanding_qardan__repayments", label: "Outstanding Qardan Hasana Repayments", fieldArea: { xs: 12, sm: 6 } },
                                { type: "semi-number", name: "other_outgoings", label: "Other Outgoings (Do not include your Annual/Anjuman Charitable Contribution amounts here)", fieldArea: { xs: 12, sm: 6 }, inputProps: { startAdornment } },
                                {
                                    type: "custom", name: "charitable_contributions_annual", label: "Charitable Contributions (Annual)", fieldArea: { xs: 12, sm: 6 },
                                    CustomField: ({ label, name, }) => {
                                        const amount = Number(deeniExpenses.amount_offered_charitable_annual);
                                        const [field, meta, helper] = useField(name);
                                        useEffect(() => { helper.setValue(isNaN(amount) ? 0 : amount === Infinity ? 0 : amount) }, [amount])
                                        return <div style={{ margin: '10px 0', }} >
                                            <Typography>{label}</Typography>
                                            <Typography>£ {field.value?.toFixed?.(2)}</Typography>
                                        </div>
                                    },
                                },
                                {
                                    type: "custom", name: "charitable_contributions_anjuman", label: "Charitable Contributions (Anjuman)", fieldArea: { xs: 12, sm: 6 },
                                    CustomField: ({ label, name, }) => {
                                        const amount = Number(deeniExpenses.amount_offered_charitable_anjuman);
                                        const [field, meta, helper] = useField(name);
                                        useEffect(() => { helper.setValue(isNaN(amount) ? 0 : amount === Infinity ? 0 : amount) }, [amount])
                                        return <div style={{ margin: '10px 0', }} >
                                            <Typography>{label}</Typography>
                                            <Typography>£ {field.value?.toFixed?.(2)}</Typography>
                                        </div>
                                    },
                                },
                                {
                                    type: "custom", name: "outstanding_qardan_repayments", label: "Outstanding Qardan Hasana Repayments", fieldArea: { xs: 12, sm: 6 },
                                    CustomField: ({ scope, label, name, condition }) => {
                                        const { amount_due_each_month = 0 } = scope.formikProps.values?.form?.other_details?.outstanding_qardan || {};
                                        const amount = Number(amount_due_each_month);
                                        const [field, meta, helper] = useField(name);
                                        useEffect(() => { helper.setValue(isNaN(amount) ? 0 : amount === Infinity ? 0 : amount) }, [amount])
                                        return <div style={{ margin: '10px 0', }} >
                                            <Typography>{label}</Typography>
                                            <Typography>£ {field.value?.toFixed?.(2)}</Typography>
                                        </div>
                                    },
                                },
                                {
                                    type: "custom", name: "total_monthly_outgoings", label: "Total Monthly Outgoings", fieldArea: { xs: 12, sm: 6 },
                                    CustomField: ({ scope, label, name, condition }) => {
                                        const { subscriptions = 0, phone_bills = 0, tv_bill = 0, saloon = 0, vacations = 0, commiting_for_work = 0, vehicle_maintenance = 0, dining_out = 0, groceries = 0, gas = 0, electricity = 0, water = 0, internet = 0, fuel_for_household = 0, council_tax = 0, pensions_contribution = 0, house_rent = 0, insurance = 0, medical = 0, other_outgoings = 0, charitable_contributions_annual = 0, charitable_contributions_anjuman = 0 } = scope.formikProps.values?.form?.other_details?.monthly_outgoings || {};
                                        const amount = Number(subscriptions) + Number(phone_bills) + Number(tv_bill) + Number(saloon) + Number(vacations) + Number(commiting_for_work) + Number(vehicle_maintenance) + Number(dining_out) + Number(groceries) + Number(gas) + Number(electricity) + Number(water) + Number(internet) + Number(fuel_for_household) + Number(council_tax) + Number(pensions_contribution) + Number(house_rent) + Number(insurance) + Number(medical) + Number(other_outgoings) + Number(charitable_contributions_annual) + Number(charitable_contributions_anjuman);
                                        const [field, meta, helper] = useField(name);
                                        useEffect(() => { helper.setValue(isNaN(amount) ? 0 : amount === Infinity ? 0 : amount) }, [amount])
                                        return <div style={{ margin: '10px 0', }} >
                                            <Typography>{label}</Typography>
                                            <Typography>£ {field.value?.toFixed?.(2)}</Typography>
                                        </div>
                                    },
                                },
                                { type: "file", name: "outgoings_document", label: "Outgoings Document", accept: ['jpg', 'pdf', 'png', 'jpeg', 'docx', 'doc', 'zip'], fieldArea: { xs: 12, sm: 6 } },
                                { type: "radio", name: "has_any_future_financial_commitments", label: "Do you know of any future financial commitments which may effect your monthly income or outgoings moving forward?", options: ['Yes', "No"], fieldArea: { xs: 12 } },
                                {
                                    type: "text-area", name: "details_of_reson_for_change", label: "Please provide details and reason for change", fieldArea: { xs: 12 },
                                    condition: { fieldName: "other_details.monthly_outgoings.has_any_future_financial_commitments", value: ["Yes"] }
                                },
                            ],
                        },

                        {
                            type: 'fieldGroup', name: "interest_involvement", fieldArea: 12, label: <Typography gutterBottom>Interest Involvement</Typography>,
                            fieldGroup: [
                                { type: "radio", name: 'has_interest_involvement', label: "Are you dealing in interest (Riba) of any sort?", options: ["Yes", "No"], fieldArea: { xs: 12 } },
                                {
                                    type: "text-area", name: "details", label: "Please provide details", fieldArea: { xs: 12 },
                                    condition: { fieldName: "other_details.interest_involvement.has_interest_involvement", value: ["Yes"] }
                                },
                            ]
                        },

                        {
                            type: 'fieldGroup', name: "bank_account_details", fieldArea: 12, label: <Typography gutterBottom>Bank Account Details</Typography>,
                            fieldGroup: [
                                { type: "text", name: 'account_name', label: "Account Name", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: 'account_number', label: "Account Number", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: 'bank_name', label: "Bank Name", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: 'bank_address', label: "Bank Address", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: 'sort_code', label: "Sort Code", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: 'name_on_check', label: "Cheque to be made in the name of", fieldArea: { xs: 12, sm: 6, md: 4 } },
                            ]
                        },

                    ]
                },
                ...FormButtons()
            ]}
        />
    )
}

function Step6({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const dispatch = useAppDispatch();
    return (
        <Form
            initialValues={values}
            enableReinitialize
            handleValidate={({ form }) => {
                const errors = {};
                checkFileSizeAndSetError(errors, 'guarantor.g_1.photo', form.guarantor?.g_1?.photo || [])
                checkFileSizeAndSetError(errors, 'guarantor.g_1.passport', form.guarantor?.g_1?.passport || [])
                checkFileSizeAndSetError(errors, 'guarantor.g_2.photo', form.guarantor?.g_2?.photo || [])
                checkFileSizeAndSetError(errors, 'guarantor.g_2.passport', form.guarantor?.g_2?.passport || [])
                return Object.keys(errors).length > 0 ? { form: errors } : {};
            }}
            onSubmit={values => {
                // console.log(JSON.stringify(values))
                dispatch(markFormCompleted({ step: _step, values }));
            }}
            fields={[
                {
                    type: "fieldGroup", fieldArea: 12, name: "guarantor",
                    root: true,
                    label: <s.LabelWrap>
                        {/* <span style={{ fontSize: "30px" }} ><b>£</b></span> */}
                        <span><Person fontSize='large' /></span>
                        <Typography variant='h5' align='center' >Guarantor Details</Typography>
                        {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ gap: '5px' }}><AccountCircle fontSize='large' />Personal Details</Box>, */}
                    </s.LabelWrap>,
                    fieldGroup: [
                        {
                            type: "fieldGroup", name: "g_1", label: <Typography variant='h5' gutterBottom >Guarantor 1</Typography>, fieldArea: 12,
                            fieldGroup: [
                                { name: "title", type: "select", label: 'Title', fieldArea: { xs: 12, sm: 6, md: 3 }, options: ["Bhai", "Behen", "Shaikh", "Mulla"] },
                                { name: "first_name", type: "text", label: 'First Name', fieldArea: { xs: 12, sm: 6, md: 3 } },
                                { name: "middle_name", type: "text", label: 'Middle Name', notRequired: true, fieldArea: { xs: 12, sm: 6, md: 3 } },
                                { name: "last_name", type: "text", label: 'Last Name', fieldArea: { xs: 12, sm: 6, md: 3 } },

                                { type: "file", name: 'photo', label: 'Photo', accept: ".png, .jpg, .jpeg", fieldArea: { xs: 12 }, description: <>Upload a passport size photo wearing a topi / rida <br /> Accepted file types: jpg, jpeg, png, Max file size: 2 MB</> },
                                { type: "file", name: 'passport', label: 'Photo ID', accept: ".png, .jpg, .jpeg, .pdf, gif", fieldArea: { xs: 12 }, description: <>i.e. Passport, Driver’s License, Govt Issued ID or ITS Card.<br /> Accepted file types: jpg, jpeg, gif, png, pdf, Max file size: 2 MB <div style={{ marginBottom: '10px' }}></div></> },

                                {
                                    type: "fieldGroup", name: "address", label: "", fieldArea: 12,
                                    fieldGroup: [
                                        { type: "text", name: 'street_address', label: 'Street Address', fieldArea: 12 },
                                        { type: "text", name: 'city', label: 'City', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                        { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), fieldArea: { xs: 12, sm: 6, md: 4 }, value: 'United Kingdom' },
                                        { type: "text", name: 'post_code', label: 'ZIP / Postal Code', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                    ]
                                },

                                { type: "its-number", name: "its_no", label: "ITS Number", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "tel", name: "mobile", label: "Mobile", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "email", name: "email", label: "Email", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: "position_in_community", label: "Position in the community held", fieldArea: { xs: 12, sm: 6, md: 4 } },
                            ]
                        },

                        {
                            type: "fieldGroup", name: "g_2", label: <Typography variant='h5' gutterBottom >Guarantor 2</Typography>, fieldArea: 12,
                            fieldGroup: [
                                { name: "title", type: "select", label: 'Title', fieldArea: { xs: 12, sm: 6, md: 3 }, options: ["Bhai", "Behen", "Shaikh", "Mulla"] },
                                { name: "first_name", type: "text", label: 'First Name', fieldArea: { xs: 12, sm: 6, md: 3 } },
                                { name: "middle_name", type: "text", label: 'Middle Name', notRequired: true, fieldArea: { xs: 12, sm: 6, md: 3 } },
                                { name: "last_name", type: "text", label: 'Last Name', fieldArea: { xs: 12, sm: 6, md: 3 } },

                                { type: "file", name: 'photo', label: 'Photo', accept: ".png, .jpg, .jpeg", fieldArea: { xs: 12 }, description: <>Upload a passport size photo wearing a topi / rida <br /> Accepted file types: jpg, jpeg, png, Max file size: 2 MB</> },
                                { type: "file", name: 'passport', label: 'Photo ID', accept: ".png, .jpg, .jpeg, .pdf, gif", fieldArea: { xs: 12 }, description: <>i.e. Passport, Driver’s License, Govt Issued ID or ITS Card.<br /> Accepted file types: jpg, jpeg, gif, png, pdf, Max file size: 2 MB <div style={{ marginBottom: '10px' }}></div></> },

                                {
                                    type: "fieldGroup", name: "address", label: "", fieldArea: 12,
                                    fieldGroup: [
                                        { type: "text", name: 'street_address', label: 'Street Address', fieldArea: 12 },
                                        { type: "text", name: 'city', label: 'City', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                        { type: "select", name: 'country', label: 'Country', options: countries.map(({ name }) => name), fieldArea: { xs: 12, sm: 6, md: 4 }, value: 'United Kingdom' },
                                        { type: "text", name: 'post_code', label: 'ZIP / Postal Code', fieldArea: { xs: 12, sm: 6, md: 4 } },
                                    ]
                                },

                                { type: "its-number", name: "its_no", label: "ITS Number", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "tel", name: "mobile", label: "Mobile", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "email", name: "email", label: "Email", fieldArea: { xs: 12, sm: 6, md: 4 } },
                                { type: "text", name: "position_in_community", label: "Position in the community held", fieldArea: { xs: 12, sm: 6, md: 4 } },
                            ]
                        },
                    ],
                },
                ...FormButtons()
            ]}
        />
    )
}

function Step7({ step: _step }: { step: number }) {
    const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
    const dispatch = useAppDispatch();
    // Additional Information
    return (
        <Form
            initialValues={values}
            enableReinitialize
            onSubmit={async values => {
                try {
                    console.log(console.log(values))
                    dispatch(markFormCompleted({ step: _step, values }));
                    dispatch(validateFormDataBeforeSubmission());
                    const res = await dispatch(submitFormData()).unwrap()
                    if (res === null) return
                    await SuccessDialog("Form Submitted", "Your application has been submitted successfully. One of the Qardan Hasana committee members will be in touch soon.")
                    if (res && process.env.NODE_ENV !== "development") {
                        dispatch(resetForm());
                        navigate("/") // move to home
                    }
                } catch (error: any) {
                    ErrorDialog("Error", error.message || "Something Went Wrong!");
                }
            }}
            fields={[
                {
                    type: "fieldGroup", fieldArea: 12, name: "additional_info",
                    root: true,
                    label: <s.LabelWrap>
                        <span style={{ fontSize: "30px" }} ><b>!</b></span>
                        <Typography variant='h5' align='center' >Additional Information</Typography>
                        {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ gap: '5px' }}><AccountCircle fontSize='large' />Personal Details</Box>, */}
                    </s.LabelWrap>,
                    fieldGroup: [
                        { type: "text-area", name: "other_info", label: "Any other information that you would like to provide", fieldArea: { xs: 12 } },
                    ]
                },
                // ...FormButtons()
                {
                    type: "text-note", name: 'separator', label: '', fieldArea: 12, element: <Box my='10px' ><hr /></Box>
                },
                {
                    type: "submit", name: 'submit-btn', label: 'Submit', fieldArea: 12,
                },
            ]}
        />
    )
}

// function Step8({ step: _step }: { step: number }) {
//     const { completed, errors, values } = useAppSelector(s => s.PersonalQardanForm.formSteps[_step - 1]);
//     const dispatch = useAppDispatch();
//     // Additional Information
//     return (
//         <Box
//             bgcolor='white'
//             boxShadow="2px 2px 10px rgb(0,0,0,0.3)"
//             padding='15px'
//             borderRadius='5px'
//         >
//             <s.LabelWrap>
//                 <Typography variant='h5' align='center' >Please review the below details and then submit the form</Typography>
//             </s.LabelWrap>
//             <br />
//             <Box>

//             </Box>
//         </Box>
//     )
// }

function FormButtons() {
    return [
        {
            type: "text-note", name: 'separator', label: '', fieldArea: 12, element: <Box my='10px' ><hr /></Box>
        },
        {
            type: "submit", name: 'submit-btn', label: 'Save & Next', fieldArea: 12,
        },
        {
            type: "custom", ignore: true, label: "", name: "_error_",
            CustomField({ scope }) {
                // console.log(scope.formikProps.dirty, scope.formikProps.initialTouched, scope.formikProps.isInitialValid)
                // console.log(scope.formikProps.touched)
                if (Object.keys(scope.formikProps.touched).length === 0) return null
                if (Object.keys(scope.formikProps.errors).length === 0) return null
                return <Typography align='center' style={{ color: "red", marginTop: "10px" }} >Please fill out required fields</Typography>
            }
        }
    ] as CombineFieldInterface[]
}



