import { Typography, Hidden } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { FC, useEffect, useState, memo } from 'react';
import * as s from './style';
import DropDown from './DropDown';
import LogoAnim from './LogoAnim';
import LeftDrawer from './LeftDrawer';
import { ExpandMore, AccountCircle } from '@material-ui/icons';
const sbqhTitle = require('../../assets/SBQH-title.png');
import { SBQHtitle } from './LogoAnim';
import config from '../../../app-config.json';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { useAppSelector } from '../../reduxStore/store';

export interface Props {
    slimHeader?: boolean,
}

const { MOHAMMADI_SCHEME, TAHERI_SCHEME, HUSAIN_SCHEME, ADMIN, QARDAN_HASANA } = config.pages;
export const schemeNames = [MOHAMMADI_SCHEME, TAHERI_SCHEME, HUSAIN_SCHEME];
const mohammadiSchemePayForm = { path: `${MOHAMMADI_SCHEME.path}${MOHAMMADI_SCHEME.routes.payentForm.path}`, title: MOHAMMADI_SCHEME.title };
const taheriSchemePayForm = { path: `${TAHERI_SCHEME.path}${TAHERI_SCHEME.routes.payentForm.path}`, title: TAHERI_SCHEME.title };
export const paymentForm = [mohammadiSchemePayForm, taheriSchemePayForm];
export const signInLinks = [
    { path: `${HUSAIN_SCHEME.path}${HUSAIN_SCHEME.routes.login.path}`, title: `${HUSAIN_SCHEME.title} Login` },
    { path: `${HUSAIN_SCHEME.path}${HUSAIN_SCHEME.routes.newAccount.path}`, title: `New ${HUSAIN_SCHEME.title} Account` },
    { path: `${QARDAN_HASANA.path}${QARDAN_HASANA.routes.login.path}`, title: `${QARDAN_HASANA.title} Login` },
    { path: `${ADMIN.path}${ADMIN.routes.login.path}`, title: `Admin Login` },
];
export const applyQardanLinks = [
    { path: QARDAN_HASANA.routes.apply.routes.business.absolutePath, title: `${QARDAN_HASANA.routes.apply.routes.business.title}` },
    { path: QARDAN_HASANA.routes.apply.routes.personal.absolutePath, title: `${QARDAN_HASANA.routes.apply.routes.personal.title}` },
];
//////////////////////////////////  component ////////////////////////////////
const Header: FC<Props> = ({ slimHeader = false }) => {
    const [openDropDown, setOpenDropDown] = useState<{ schemes: boolean, contributeOnline: boolean, signIn: boolean, applyQardan: boolean }>(
        { schemes: false, contributeOnline: false, signIn: false, applyQardan: false }
    );
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    /* showHeader state works when slimHeader is true */
    const [showHeader, setShowHeader] = useState<boolean>(false);

    const hsLoggedIn = useAppSelector(s => s.hsPortal.isAuthenticated);
    const sbqhLoggedIn = useAppSelector(s => s.sbqhPortal.isAuthenticated);

    useEffect(() => {
        let mount = true
        if (slimHeader) {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 160 && !showHeader) {
                    if (mount) setShowHeader(true)
                }
                else if (window.scrollY < 160 && !showHeader) {
                    if (mount) setShowHeader(false)
                }
            })
        }
        return () => { mount = false; }
    }, [])



    return (
        <s.HeaderWrap isFixed={slimHeader} showHeader={showHeader} >
            <LogoAnim hideLogoLoop={slimHeader} animationOff={slimHeader} />
            <Hidden mdUp><LeftDrawer open={drawerOpen} setOpen={setDrawerOpen} /></Hidden>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                <Hidden smDown ><s.NavLinks justify='flex-start'>
                    <li><Link to={config.pages.HOME.path}><Typography><b>{config.pages.HOME.title}</b></Typography></Link></li>
                    <li id='__scheme-links_'
                        onMouseEnter={() => { setOpenDropDown({ ...openDropDown, schemes: true }) }}
                        onMouseLeave={() => { setOpenDropDown({ ...openDropDown, schemes: false }) }}
                    >
                        <Typography style={{ cursor: 'pointer' }} ><b>Schemes</b></Typography>
                        <ExpandMore style={{ cursor: 'pointer' }} />
                        {openDropDown.schemes && <DropDown links={schemeNames} open={openDropDown.schemes} x={20} y={99} />}
                    </li>
                    <li id='__contact-link_'><Link to={config.pages.CONTACT.path}><Typography><b>{config.pages.CONTACT.title}</b></Typography></Link></li>
                    <li><Link to={config.pages.ABOUT_US.path}><Typography><b>{config.pages.ABOUT_US.title}</b></Typography></Link></li>
                </s.NavLinks></Hidden>

                <s.NavTitle>
                    <Hidden mdUp ><div><IconButton onClick={() => setDrawerOpen(!drawerOpen)} ><Menu /></IconButton></div></Hidden>
                    <SBQHtitle animDelay={"1.5s"} src={sbqhTitle} />
                    <Hidden mdUp><div /></Hidden>
                </s.NavTitle>

                <Hidden smDown ><s.NavLinks compact={true} justify='flex-end'>
                    <li id='__contribure-online_'
                        onMouseEnter={(e) => { setOpenDropDown({ ...openDropDown, contributeOnline: true }) }}
                        onMouseLeave={() => { setOpenDropDown({ ...openDropDown, contributeOnline: false }) }}
                    >
                        <Typography style={{ cursor: 'pointer' }} ><b>Contribute Qardan Hasana</b></Typography>
                        <ExpandMore style={{ cursor: 'pointer' }} />
                        {openDropDown.contributeOnline && <DropDown links={paymentForm} open={openDropDown.contributeOnline}
                            x={-0} y={73} /*x={0} y={72}*/
                        />}
                    </li>
                    <li id='__apply-qardan_'
                        onMouseEnter={(e) => { setOpenDropDown({ ...openDropDown, applyQardan: true }) }}
                        onMouseLeave={() => { setOpenDropDown({ ...openDropDown, applyQardan: false }) }}
                    >
                        <Typography><b>{config.pages.APPLY_QARDAN_HASANA.title}</b></Typography>
                        <ExpandMore style={{ cursor: 'pointer' }} />
                        {openDropDown.applyQardan && <DropDown width={220} links={applyQardanLinks} open={openDropDown.applyQardan}
                            x={-32} y={73} /*x={0} y={72}*/
                        />}
                        {/* <Link to={config.pages.APPLY_QARDAN_HASANA.path}>
                            <Typography><b>{config.pages.APPLY_QARDAN_HASANA.title}</b></Typography>
                        </Link> */}
                    </li>
                    <li
                        onMouseEnter={(e) => { setOpenDropDown({ ...openDropDown, signIn: true }) }}
                        onMouseLeave={() => { setOpenDropDown({ ...openDropDown, signIn: false }) }}
                    >
                        <Typography style={{ cursor: 'pointer' }} >
                            {(hsLoggedIn || sbqhLoggedIn) ?
                                <b>Signed In</b> :
                                <b>Sign In</b>
                            }
                        </Typography>
                        <AccountCircle style={{ cursor: 'pointer' }} />
                        {openDropDown.signIn && <DropDown width={245} links={signInLinks} open={openDropDown.signIn}
                            x={-72} y={128} /*x={0} y={72}*/
                        />}
                    </li>
                </s.NavLinks></Hidden>
            </div>
        </s.HeaderWrap >
    )
};


export default memo(Header);
