import { setValueInObjectField } from ".";
import { StateType as PersonalQardanStateType } from "../reduxStore/slices/PersonalQardanFormSlice";


export class ApplyQardanHasanaFormFields {
  static storageKey: string = 'apply-qardan-form-values';

  static get = () => {
    const jsonSting = localStorage.getItem(ApplyQardanHasanaFormFields.storageKey);
    if (jsonSting) {
      return JSON.parse(jsonSting) as { formvalues: object };
    }
    return null;
  };

  static set = ({ formvalues }: { formvalues: object }) => {
    localStorage.setItem(ApplyQardanHasanaFormFields.storageKey, JSON.stringify({ formvalues }));
  };

  static remove = () => {
    localStorage.removeItem(ApplyQardanHasanaFormFields.storageKey);
  };
}

export class ApplyPersonalQardanHasanaFormState {
  static storageKey: string = 'personal-qardan-state-values';

  static get = () => {
    const jsonSting = localStorage.getItem(ApplyPersonalQardanHasanaFormState.storageKey);
    if (jsonSting) {
      return JSON.parse(jsonSting) as { state: PersonalQardanStateType };
    }
    return null;
  };

  static set = ({ state }: { state: PersonalQardanStateType }) => {
    const parsedObj: { state: PersonalQardanStateType } = JSON.parse(
      JSON.stringify({ state })//.replace(/:{"0":{}}/g, `:""`).replace(/:\[{}\]/g, `:""`)
    );

    [
      "0.values.personal.applicant_photo",
      "0.values.personal.applicant_passport",
      "0.values.personal.property_details.proof_of_address",
      "0.values.personal.residential_status_in_uk.biometric_residence_permit",

      "1.values.deeni_expenses.safai_chitti",

      "3.values.qardan_hasana_details.property_details.property_docs",
      "3.values.qardan_hasana_details.education_details.educational_docs",

      "4.values.other_details.educational_details.certificate",
      "4.values.other_details.monthly_income.income_document",
      "4.values.other_details.monthly_outgoings.outgoings_document",
      
      "5.values.guarantor.g_1.photo",
      "5.values.guarantor.g_1.passport",
      "5.values.guarantor.g_2.photo",
      "5.values.guarantor.g_2.passport",

    ]
      .forEach(key => {
        setValueInObjectField(parsedObj.state.formSteps, key, "");
      })

    localStorage.setItem(
      ApplyPersonalQardanHasanaFormState.storageKey,
      JSON.stringify(parsedObj)
    );
  };

  static remove = () => {
    localStorage.removeItem(ApplyPersonalQardanHasanaFormState.storageKey);
  };
}
