import axios, { AxiosError } from 'axios';
import { Body, BodyRes } from '../../../aws-cdk/lambda-fns/get-s3-signed-url';
import { endpoints } from '../../app-config.json';

type ResponseData = {
    data?: BodyRes
    error?: AxiosError<BodyRes>
}


export const getS3SignedUrl = async (body: Body): Promise<ResponseData> => {
    const url = endpoints['get-s3-signed-url'];
    try {
        const { data } = await axios.post<{}, { data: BodyRes }>(url, body, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        console.log(data);
        return { data }

    } catch (error: any) {
        console.log("getS3SignedUrl==>", error.response.data);
        return { error: error.response.data } as any
    }
}