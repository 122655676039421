import axios, { AxiosError } from 'axios';
import { } from '../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import FORM_VALUES from '../../../aws-cdk/lambda-fns/apply-personal-qardan-hasana/form-fields.json';
import { FileFields } from '../../../aws-cdk/lambda-fns/apply-qardan-hasana';
import { GeneratePDFconfig } from '../../../aws-cdk/lambda-fns/commonTypeDefs';
import { endpoints } from '../../app-config.json';
import { ErrorDialog } from '../components/Alert';
import { getFileExt } from '../helper';
// https://stackoverflow.com/questions/8608724/how-to-zip-files-using-javascript
import { getS3SignedUrl } from './getS3SignedUrl';

export const applyPersonalQardanHasana = async ({ formValues }: { formValues: typeof FORM_VALUES }) => {

    // console.log("formValues.final ==>", jsonFormValues); return;

    /**
     File Paths {
        personal.applicant_photo
        personal.applicant_passport
        personal.property_details.proof_of_address
        personal.residential_status_in_uk.biometric_residence_permit
        
        deeni_expenses.safai_chitti
        
        qardan_hasana_details.property_details.property_docs
        qardan_hasana_details.education_details.educational_docs
        
        other_details.educational_details?.certificate
        other_details.monthly_income?.income_document
        other_details.monthly_outgoings?.outgoings_document

        guarantor.g_1?.photo
        guarantor.g_1?.passport
        guarantor.g_2?.photo
        guarantor.g_2?.passport
     }
     */

    try {
        const its_number = formValues.personal.demographic.its_no;

        /* merging all files in formdata object */
        const files: { [key: string]: File[] | Blob[] | undefined } = {
            applicant_photo: formValues.personal.applicant_photo as any,
            applicant_passport: formValues.personal.applicant_passport as any,
            proof_of_address: formValues.personal.property_details.proof_of_address as any,
            biometric_residence_permit: formValues.personal.residential_status_in_uk.biometric_residence_permit as any,

            deeni_expenses_safai_chitti: formValues.deeni_expenses.safai_chitti as any,

            property_docs: formValues.qardan_hasana_details.property_details.property_docs as any,
            educational_docs: formValues.qardan_hasana_details.education_details.educational_docs as any,

            certificate: formValues.other_details.educational_details?.certificate as any,
            income_document: formValues.other_details.monthly_income?.income_document as any,
            outgoings_document: formValues.other_details.monthly_outgoings?.outgoings_document as any,

            guarantor_1_photo: formValues.guarantor.g_1?.photo as any,
            guarantor_1_passport: formValues.guarantor.g_1?.passport as any,
            guarantor_2_photo: formValues.guarantor.g_2?.photo as any,
            guarantor_2_passport: formValues.guarantor.g_2?.passport as any,
        }

        console.log("files", files);
        /* Collecting all files in an array */
        const filesList: { file: Blob, key: string }[] = []
        Object.entries(files).forEach(([name, files]) => {
            if (files?.[0] instanceof File || files?.[0] instanceof Blob)
                files.length > 1 ?
                    files.forEach((file, i) => {
                        filesList.push({ file, key: `personal-qh/${its_number}/${name}_${i + 1}${getFileExt(file.name)}` })
                    }) :
                    filesList.push({ file: files[0], key: `personal-qh/${its_number}/${name}${getFileExt(files[0].name)}` })
        });

        console.log("filesList ==>", filesList)

        /* Getting signed put urls for files */
        const { data, error } = await getS3SignedUrl({ operation: 'putObject', keys: filesList.map(f => f.key) })
        if (error) throw error;
        const signedUrls = data?.signedUrls || [];

        const uploadRes = await Promise.all(filesList.map(({ file }, idx) => {
            return axios.put(signedUrls[idx], file, {
                headers: {
                    "Content-Type": file.type,
                }
            })
        }))
        console.log("FileUploadToS3res ==>", uploadRes.map(v => v.data))

        formValues.file_keys = filesList.map(f => f.key)

        const jsonFormValues = JSON.stringify(formValues);
        const formdata = new FormData();
        formdata.append("formvalues", jsonFormValues);

        const apiEndpoint = endpoints['apply-personal-qardan-hasana'];
        const resp = await axios.post(apiEndpoint, formdata, { headers: { 'Content-Type': "multipart/form-data" } });
        console.log("applyPersonalQardanHasana.Resp ===>", resp.data);
        return { data: resp.data }

    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log("isAxiosError.applyQardanHasana_Error_response1", Object.entries(error));
            console.log("isAxiosError.applyQardanHasana_Error_response2", error.response);
            return { error: error.response?.data || "Unable to submit the form. Something went wrong" }
        }
        console.log("applyQardanHasana_Error_response", error);
        return { error: error as AxiosError }
    }
}
