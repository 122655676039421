import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ApplyPersonalQardanHasanaFormState } from '../../../helper/local-storage-handler';
import { submitFormData } from './asyncThunks';
import { ErrorDialog } from '../../../components/Alert';

export interface StateType {
    activeStep: number;
    allStepsCompleted: boolean,
    formSteps: {
        step: number;
        values: any;
        errors: any;
        completed: boolean,
    }[],
}

const initialState: StateType = {
    activeStep: 1,
    allStepsCompleted: false,
    formSteps: [
        { step: 1, errors: null, values: null, completed: false },
        { step: 2, errors: null, values: null, completed: false },
        { step: 3, errors: null, values: null, completed: false },
        { step: 4, errors: null, values: null, completed: false },
        { step: 5, errors: null, values: null, completed: false },
        { step: 6, errors: null, values: null, completed: false },
        { step: 7, errors: null, values: null, completed: false },
        // { step: 8, errors: null, values: null, completed: false },
    ]
}

const slice = createSlice({
    name: "PersonalQardanHasanaFormSlice",
    initialState,
    reducers: {
        resetForm() {
            ApplyPersonalQardanHasanaFormState.remove()
            return initialState
        },
        markFormCompleted(state, { payload }: PayloadAction<{ step: number, values: any }>) {
            const idx = state.formSteps.findIndex(f => f.step === payload.step);
            if (idx === -1) return
            if (!state.formSteps[idx]) return

            state.formSteps[idx].completed = true;
            state.formSteps[idx].values = payload.values;
            if ((state.activeStep + 1) <= state.formSteps.length) {
                state.activeStep++
            }
            /* Saving form state in local storage */
            ApplyPersonalQardanHasanaFormState.set({ state })

        },
        setActiveStep(state, { payload }: PayloadAction<{ step: number }>) {
            state.activeStep = payload.step;
        },
        loadFormState(state) {
            return ApplyPersonalQardanHasanaFormState.get()?.state as any || state;
        },
        validateFormDataBeforeSubmission(state) {
            state.allStepsCompleted = true;

            if (
                !(state.formSteps[0].values.personal.applicant_passport?.[0] instanceof File) ||
                !(state.formSteps[0].values.personal.applicant_photo?.[0] instanceof File) ||
                !(state.formSteps[0].values.personal.property_details.proof_of_address?.[0] instanceof File)
            ) {
                state.formSteps[0].completed = false;
                state.allStepsCompleted = false;
            }

            if (state.formSteps[0].values.personal.residential_status_in_uk?.property_status === "Others" &&
                !(state.formSteps[0].values.personal.residential_status_in_uk.biometric_residence_permit?.[0] instanceof File)
            ) {
                state.formSteps[0].completed = false;
                state.allStepsCompleted = false;
            }

            if (
                !(state.formSteps[1].values.deeni_expenses.safai_chitti?.[0] instanceof File)
            ) {
                state.formSteps[1].completed = false;
                state.allStepsCompleted = false;
            }

            if (state.formSteps[3].values.qardan_hasana_details.purpose === "Housing" &&
                !(state.formSteps[3].values.qardan_hasana_details.property_details.property_docs?.[0] instanceof File)
            ) {
                state.formSteps[3].completed = false;
                state.allStepsCompleted = false;
            }

            if (state.formSteps[3].values.qardan_hasana_details.purpose === "Education" &&
                !(state.formSteps[3].values.qardan_hasana_details.education_details.educational_docs?.[0] instanceof File)
            ) {
                // console.log("state.formSteps[3] ==>",state.formSteps[3].values.qardan_hasana_details.purpose)
                // console.log("state.formSteps[3] ==>",state.formSteps[3].values.qardan_hasana_details.education_details.educational_docs)
                state.formSteps[3].completed = false;
                state.allStepsCompleted = false;
            }

            if (
                !(state.formSteps[4].values.other_details.educational_details?.certificate?.[0] instanceof File) ||
                !(state.formSteps[4].values.other_details.monthly_income?.income_document?.[0] instanceof File) ||
                !(state.formSteps[4].values.other_details.monthly_outgoings?.outgoings_document?.[0] instanceof File)
            ) {
                state.formSteps[4].completed = false;
                state.allStepsCompleted = false;
            }

            if (
                !(state.formSteps[5].values.guarantor.g_1?.photo?.[0] instanceof File) ||
                !(state.formSteps[5].values.guarantor.g_1?.passport?.[0] instanceof File) ||
                !(state.formSteps[5].values.guarantor.g_2?.photo?.[0] instanceof File) ||
                !(state.formSteps[5].values.guarantor.g_2?.passport?.[0] instanceof File)
            ) {
                state.formSteps[5].completed = false;
                state.allStepsCompleted = false;
            }

            for (let step of state.formSteps) {
                if (step.completed === false) {
                    ErrorDialog(`Incomplete Form`, `Please complete step ${step.step}`)
                    state.allStepsCompleted = false;
                    break;
                }

            }

        }
        // setFormError(state, { payload }: PayloadAction<{ step: number }>) {
        //     state.activeStep = payload.step;
        // },

    },
    extraReducers(builder) {
        builder.addCase(submitFormData.pending, () => { });
        builder.addCase(submitFormData.fulfilled, () => { });
        builder.addCase(submitFormData.rejected, () => { });
    }
})

export default slice.reducer;

export const { markFormCompleted, resetForm, setActiveStep, loadFormState, validateFormDataBeforeSubmission } = slice.actions;
export { submitFormData }

