import { createAsyncThunk } from "@reduxjs/toolkit";
import { applyPersonalQardanHasana } from "../../../services/apply-personal-qardan-hasana";
import { RootStateType } from "../../store";

export const submitFormData = createAsyncThunk(
    "submitFormData",
    async (data = undefined, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as RootStateType;
            if (state.PersonalQardanForm.allStepsCompleted === false) return null;
            let formValues: any = {};
            state.PersonalQardanForm.formSteps.forEach(v => formValues = { ...formValues, ...v.values })
            const { error, data } = await applyPersonalQardanHasana({ formValues })
            if (data) return data;
            return thunkAPI.rejectWithValue(error);

        } catch (error: any) {
            console.log("submitFormData-error", error.response.data)
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)