import React, { FC } from 'react';
import { Seo, PageLayout } from '../../../components';
import { PageProps } from 'gatsby';
import { Typography } from '@material-ui/core';
import config from '../../../../app-config.json';
import * as s from '../../../style.pages/index.style';
import { siteMetadata } from '../../../../gatsby-config';
import PersonalQardanHasana from '../../../components/Form/PersonalQardanHasana';

const PARENT_PAGE_TITLE = config.pages.QARDAN_HASANA.title;
// const PAGE_TITLE = config.pages.QARDAN_HASANA.routes.apply.title;
const PAGE_TITLE = "Apply Personal Qardan Hasana";

//////////////////////////////////  component ////////////////////////////////
const ApplyQardan: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${PARENT_PAGE_TITLE} | ${siteMetadata.title}`} indexing={false} />
            <s.BodyWrap >
                <br />
                <Typography variant='h2' >{PAGE_TITLE.replace('(London)', "")} Application Form</Typography>
                <hr /><br /> <br />
                <Typography variant='body1' gutterBottom component='div'  >
                    {/* <h2>Note:</h2>
                    <s.TypoList type='ul'>
                        <li style={{ fontWeight: "bold", }} >Currently we are accepting Business Qardan Hasana
                            applications <Typography component="span" style={{ color: "red", fontWeight: "inherit" }} >for over £25,000</Typography> only.
                            If you need any further information, you can call {config.WEBSITE_TITLE} office on {config.pages.CONTACT.details.officePhNo}.
                        </li>
                    </s.TypoList>
                    <br /><br /> */}
                    <h2>Important rules</h2>
                    <s.TypoList type='ol'>
                        {/* <li>The application must be received at least three days prior to the trustees meeting. Meeting is held on the first Monday of each month.</li> */}
                        <li>Jamat safai chitthi must be submitted with the application.</li>
                        <li>Only completed applications will be considered for discussion during the meeting.</li>
                        <li>The amount and terms of the Qardan Hasana sanctioned, will be at the discretion of the trustees in line with our responsible lending policy. The trustees’ decision will be final.</li>
                        <li>The applicant will be informed of the decision taken on the application.</li>
                    </s.TypoList>
                    <br /><br />
                    <h2>Guarantors & Jewellery</h2>
                    <s.TypoList type='ol'>
                        <li>In accordance with the irshadaat of Huzurala (TUS) the applicant will need to offer jewellery.</li>
                        <li>Two guarantors acceptable to the trustees will be required in support of the application.</li>
                    </s.TypoList >
                    <br /><br />
                    <h2>Repayment of Qardan Hasana</h2>
                    <s.TypoList type='ol'>
                        <li>Repayment must be made by standing order from applicant account.</li>
                        <li>Third party cheques will not be accepted.</li>
                    </s.TypoList>
                </Typography>
                <br /><hr /><br /> <br />

                <PersonalQardanHasana />

            </s.BodyWrap>
        </PageLayout>
    )
}

export default ApplyQardan;
