import React from "react";
import { Box, Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import { ArrayFieldInterface, CombineFieldInterface, FieldGroupInterface, FormConfirmationCheckBoxField, FormFileField, FormGroupField, FormInputField, FormPasswordInputField, FormRadioField, FormSelectField, FormTextNote, filterNumber, FormDateInputField, useIsVisible, } from "./utils";
import * as yup from 'yup';
import { FormikProps } from "formik";
import { StyledBtn } from "../../Button";

export class FormGenerator {
    fields: CombineFieldInterface[];
    formikProps: FormikProps<any> = {} as any;
    validationSchema?: yup.ObjectSchema<any>;
    formFieldsJsx: React.ReactNode[] = [];

    constructor(fields: CombineFieldInterface[]) {
        this.fields = fields;
    }

    setFormikProps(formikProps: FormikProps<any>) {
        this.formikProps = { ...this.formikProps, ...formikProps };
    }

    getInitialValues() {
        return { form: this.generateValues(this.fields) };
    }

    getValidationSchema() {
        return yup.object({ form: yup.object(this.generateSchema(this.fields)) });
    }

    getFormFieldsJsx() {
        return this.generateFormFields(this.fields, "form");
    }

    generateValues(fields: CombineFieldInterface[]) {
        const obj: any = {};
        fields.forEach((f) => {
            if (f.type === "fieldGroup") {
                obj[f.name] = this.generateValues(f.fieldGroup);
            } else if (f.type === "array-field") {
                obj[f.name] = [this.generateValues(f.fieldArrayGroup)];
            } else if (f.type === "text-note" || f.type === "submit") {
                return;
            } else {
                obj[f.name] = f.value || "";
            }
        });
        return obj;
    }

    private generateSchema(fields: CombineFieldInterface[]) {
        const obj: { [key: string]: yup.AnySchema } = {};
        fields.forEach((f) => {
            switch (f.type) {
                case "array-field":
                    obj[f.name] = yup.array().of(yup.object(this.generateSchema(f.fieldArrayGroup)));
                    break;
                case "fieldGroup":
                    obj[f.name] = yup.object(this.generateSchema(f.fieldGroup));
                    break;
                case "text": case "semi-number": case "tel": case "date": case "text-area":
                    obj[f.name] = yup.string(); //.required('Required');
                    break;
                case "its-number":
                    obj[f.name] = yup.string().length(8, "Length must be 8"); //.required('Required');
                    break;
                case "url":
                    obj[f.name] = yup.string().url("Invalid Url"); //.required('Required');
                    break;
                case "confirmation-checkbox":
                    obj[f.name] = yup.boolean().oneOf([true], 'Must be checked'); //.required('Required');
                    break;
                case "number":
                    obj[f.name] = yup.number(); //.required('Required');
                    break;
                case "email":
                    obj[f.name] = yup.string().email("Incorrect email format"); //.required('Required');
                    break;
                case "file":
                    obj[f.name] = yup.mixed<FileList>();
                    break;
                case "custom":
                    if (!f.ignore)
                        obj[f.name] = yup.mixed();
                    break;
                case "select": case "radio":
                    obj[f.name] = yup.string().oneOf(f.options.map(v => typeof v !== 'object' ? v : v.value), "Select a valid value"); //.required('Required');
                    break;
                case "text-note":
                    break;
                default:
                    break;
            }
            if (!f.notRequired && obj[f.name]) {
                if (f.condition) { // if this field depends on a condition
                    /* expected value of fieldName is like in this format. e.g "user.address.postcode" */
                    const splittedFields = f.condition.fieldName.split('.');
                    const endField = splittedFields[splittedFields.length - 1];
                    obj[f.name] = obj[f.name].when(endField, {
                        is: (val) => f.condition?.value.includes(val),
                        then: yup => yup.required("Required"),
                        otherwise: yup => yup.notRequired()
                    })
                }
                else
                    obj[f.name] = obj[f.name].required("Required");
            }
        });
        return obj;
    }

    private generateFormFields(fields: CombineFieldInterface[], pName: string) {
        return fields.map((f) => {
            const fieldArea = f.fieldArea || 12;

            if (f.type === "custom") {
                const isVisble = useIsVisible({ name: f.name, condition: f.condition });
                return (
                    <Grid item key={`${pName}.${f.name}`} {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })}
                        style={{ display: isVisble ? "grid" : "none", }}
                    >
                        <f.CustomField {...f}  {...{ scope: this }} onChange={(v) => this.formikProps?.setFieldValue(`${pName}.${f.name}`, v)} label={f.label} name={`${pName}.${f.name}`} notRequired={f.notRequired} />
                    </Grid>
                );
            } else if (f.type === "file") {
                return (
                    <FormFileField
                        {...f}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                        key={`${pName}.${f.name}`}
                    />
                );
            } else if (f.type === "text-note") {
                return <FormTextNote key={`${pName}.${f.name}`} {...f} />;
            } else if (f.type === "fieldGroup") {
                return (
                    <FormGroupField
                        {...f as any}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                        key={`${pName}.${f.name}`}
                        handleGenerateChildFields={() => this.generateFormFields(f.fieldGroup, `${pName}.${f.name}`)}
                    />
                );
            } else if (f.type === "select") {
                return (
                    <FormSelectField
                        {...f}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            } else if (f.type === "confirmation-checkbox") {
                return (
                    <FormConfirmationCheckBoxField
                        {...f}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            } else if (f.type === "radio") {
                return (
                    <FormRadioField
                        {...f}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            } else if (["its-number", "semi-number"].includes(f.type)) {
                return (
                    <FormInputField
                        {...f}
                        type="text"
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                        onChange={(e: any) =>
                            filterNumber({
                                e,
                                handleChange: this.formikProps?.handleChange!,
                                formating: false,
                            })
                        }
                    />
                );
            } else if (f.type === "tel") {
                return (
                    <FormInputField
                        {...f}
                        type="tel"
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                        onChange={(e: any) =>
                            filterNumber({
                                e,
                                handleChange: this.formikProps?.handleChange!,
                                include: ["+"],
                                formating: false,
                            })
                        }
                    />
                );
            } else if (["number", "datetime-local", "url"].includes(f.type)) {
                return (
                    <FormInputField
                        {...f}
                        type={f.type as any}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            } else if (["date"].includes(f.type)) {
                return (
                    <FormDateInputField
                        {...f}
                        type={f.type as any}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        notRequired={f.notRequired}
                    />
                );
            } else if (f.type === "password") {
                return (
                    <FormPasswordInputField
                        {...f}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            } else if (f.type === "submit") {
                const { isSubmitting = true } = this.formikProps;
                return (
                    <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })} key={`${pName}.${f.name}`} style={{ textAlign: "center" }}>
                        <StyledBtn
                            variant='contained' size='medium' color='primary'
                            type={f.type}
                            disabled={isSubmitting}
                            {...f.inputProps}
                        >
                            {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>{f.label}</div>}
                        </StyledBtn>
                    </Grid>
                );
            } else if (f.type === "text-area") {
                return (
                    <FormInputField
                        {...f}
                        multiline minRows={5}
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            } else {
                return (
                    <FormInputField
                        {...f}
                        type="text"
                        key={`${pName}.${f.name}`}
                        name={`${pName}.${f.name}`}
                        required={!f.notRequired}
                    />
                );
            }
        });
    }

    /**
     * this method will be used to remove unknown fields from initialValues of Form
     * @param initialValues
     * What is does: Eg: there is an object => {id:'foo', name:'bar', school: {id:'1', grade:'7th'} }
     *  after passing this object as initialValues in this method it remove unknown values like id which
     *  was not mentioned in ( fields: CombineFieldInterface[] )
     *  Eg output: { name:'bar', school: { grade:'7th' } }
     */
    removeUnknownFields(initialValues: any /* should be an object */) {
        console.log("Initial values", initialValues);
        _removeUnknownFields(this.fields, initialValues);
        function _removeUnknownFields(fieldGroup: CombineFieldInterface[], _object: any) {
            const fields = fieldGroup.map((f) => f.name);
            Object.keys(_object).forEach((f) => {
                // const fields = this.fields.map(f => f.name);
                if (!fields.includes(f)) delete _object[f];

                else if (Array.isArray(_object[f]) && (!_object[f][0] || _object[f][0] instanceof File)) {

                } else if (Array.isArray(_object[f])) {
                    const { fieldArrayGroup: _fieldArrayGroup } = fieldGroup.filter(
                        (v) => v.type === "array-field" && v.name === f
                    )[0] as ArrayFieldInterface;
                    _object[f].forEach((_childObject: any) => {
                        _removeUnknownFields(_fieldArrayGroup, _childObject);
                    })
                } else if (_object[f] === null) {
                    delete _object[f]

                } else if (_object[f] instanceof FileList || _object[f] instanceof Blob) {

                } else if (typeof _object[f] === "object") {
                    // console.log(_object);
                    const { fieldGroup: _fieldGroup } = fieldGroup.filter(
                        (v) => v.type === "fieldGroup" && v.name === f
                    )[0] as FieldGroupInterface;
                    _removeUnknownFields(_fieldGroup, _object[f]);
                }

            });
        }
        return initialValues;
    }
}