import React, { Component, ReactElement, useRef } from "react";
import { FC, HTMLProps, ReactNode, memo, useState, FunctionComponent, useEffect, useCallback, ChangeEvent } from "react";
import { ErrorMessage, FieldArray, useField } from "formik";
import {
    TextField,
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    InputLabel,
    FormHelperText,
    // Grid,
    Typography,
    IconButton,
    Checkbox,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Grid,
    GridSize,
    GridTypeMap,
    Box,
    TextFieldProps,
} from "@material-ui/core";
import { Visibility, VisibilityOff, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import styled, { keyframes } from 'styled-components';
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from '@date-io/moment';
import { FormGenerator } from "./formGenerator";

export const FormInputField = ({ type, name, label, required, fieldArea, onChange, condition, notRequired, inputProps = {}, ...others }: Omit<TextFieldProps, "label"> & FieldInterface) => {
    const [field, meta, helpers] = useField({ name: name! });

    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && helpers.setValue(''))

    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })} >
            {/* {label && <InputLabel>{label}{required && "*"}</InputLabel>} */}
            <TextField
                {...others as any}
                {...field}
                label={<>{label}{required && <span style={{ color: 'red' }}>*</span>}</>} error={meta.touched && !!meta.error}
                onChange={onChange || field.onChange}
                required={required}
                InputLabelProps={{ required: false, /* Disables the '*' from appearing*/ }}
                variant="outlined"
                type={type} fullWidth
                InputProps={inputProps}
                helperText={<Typography component='span' variant="body2" style={{ color: 'red' }} >{meta.touched && meta.error}</Typography>}
            />
        </Grid >
    );
};

export const FormDateInputField = ({ label, name, notRequired = false, condition, fieldArea, value, inputProps, ...others }: DateFieldInterface) => {
    const [field, meta, helpers] = useField({ name });
    const dateFormat = useCallback((date) => moment(date, ["DD/MM/yyyy", "yyyy/MM/DD", "DD-MM-yyyy", "yyyy-MM-DD"]), []);
    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && helpers.setValue(''))

    const fieldValue = value ? dateFormat(value).format("DD-MM-yyyy") :
        meta.value ? dateFormat(meta.value).format("DD-MM-yyyy") :
            field.value ? dateFormat(field.value).format("DD-MM-yyyy") :
                "";

    const handleChange = (value: MaterialUiPickersDate) => {
        const _val = value?.format("DD-MM-yyyy") || "";
        // console.log("DateInput_change", _val, ", previous-value", fieldValue);
        helpers.setValue(_val, true)
    }

    useEffect(() => { helpers.setValue(fieldValue) }, [])


    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })} >
            <MuiPickersUtilsProvider utils={MomentUtils} >
                <KeyboardDatePicker inputVariant='outlined' fullWidth
                    label={<>{label}{!notRequired && <span style={{ color: 'red' }}>*</span>}</>}
                    InputLabelProps={{ required: false, /* Disables the '*' from appearing*/ }}
                    required={!notRequired} format="DD/MM/yyyy" value={fieldValue && dateFormat(fieldValue)} onChange={handleChange}
                />
            </MuiPickersUtilsProvider>
            <ErrorMessage name={name} render={(msg) => <Typography variant='body2' style={{ color: 'red', textAlign: "left" }}>{msg}</Typography>} />
        </Grid>
    )
}

export const FormConfirmationCheckBoxField = ({ name, label, fieldArea, condition, inputProps = {}, }: Omit<HTMLProps<any>, "label"> & FieldInterface) => {
    const [field, meta, helpers] = useField({ name: name! });
    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && helpers.setValue(''))

    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })}>
            <FormControl component="fieldset" error={!!meta.error && meta.touched} >
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field} color="primary"
                            checked={!!field.value}
                            onChange={(event) => helpers.setValue(event.target.checked)}
                            {...inputProps}
                        />
                    }
                    label={label}
                />
                {meta.touched && meta.error && (
                    <FormHelperText>{meta.error}</FormHelperText>
                )}
            </FormControl>
        </Grid>
    );
};

export const FormPasswordInputField = ({ name, label, required, fieldArea, onChange, condition, notRequired, inputProps = {}, ...others }: Omit<HTMLProps<any>, "label"> & FieldInterface) => {
    const [showPassword, setShowPassword] = useState(false);
    const [field, meta, helpers] = useField({ name: name! });
    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && helpers.setValue(''))

    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })} >
            <TextField
                {...others as any}
                {...field}
                type={showPassword ? 'text' : 'password'}
                onChange={onChange || field.onChange}
                required={required}
                label={<>{label}{required && <span style={{ color: 'red' }}>*</span>}</>}
                InputLabelProps={{ required: false, /* Disables the '*' from appearing*/ }}
                error={meta.touched && !!meta.error}
                variant="outlined" fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    ),
                }}
                helperText={<Typography component='span' variant="body2" style={{ color: 'red' }} >{meta.touched && meta.error}</Typography>}
            />
        </Grid>
    );
};

export const FormSelectField = ({ name, label, required, options, fieldArea, condition, notRequired, inputProps = {}, ...others }: Omit<HTMLProps<any>, "label"> & SelectFieldInterface) => {
    const [field, meta, helper] = useField({ name: name! });
    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && helper.setValue(undefined))

    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })} >
            <FormControl fullWidth error={!!meta.error && meta.touched} variant="outlined" >
                <InputLabel>{label}{required && <span style={{ color: 'red' }}>*</span>}</InputLabel>
                <Select
                    {...others as any}
                    {...field}
                    onChange={field.onChange} label={label}
                    required={required}
                    InputLabelProps={{ required: false, /* Disables the '*' from appearing*/ }}
                    inputProps={inputProps}
                >
                    {/* <MenuItem value='' disabled ></MenuItem> */}
                    {options?.map((option, idx) => (
                        <MenuItem key={idx} value={typeof option === "object" ? option.value : option}>
                            {typeof option === "object" ? option.label : option}
                        </MenuItem>
                    ))}
                </Select>
                {/* <FormHelperText>{meta.touched && meta.error}</FormHelperText> */}
                <FormHelperText>{<Typography component='span' variant="body2" style={{ color: 'red' }} >{meta.touched && meta.error}</Typography>}</FormHelperText>
            </FormControl>
        </Grid>
    );
};

export const FormRadioField = ({ name, alignVertical, label, required, options, fieldArea, condition, inputProps = {}, ...others }: Omit<HTMLProps<any>, "label"> & RadioFieldInterface) => {
    const [field, meta, helpers] = useField({ name: name! });
    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && helpers.setValue(''))

    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })}>
            <FormControl component="fieldset" fullWidth error={!!meta.error && meta.touched} >
                {label && (
                    <FormLabel component="legend">
                        {label}
                        {required && <span style={{ color: 'red' }}>*</span>}
                    </FormLabel>
                )}
                <RadioGroup {...others as any} {...field}
                    onChange={(event) => helpers.setValue(event.target.value)}
                    row={!alignVertical} // Aligns vertically if alignVertical is true

                >
                    {options?.map((o, idx) => {
                        const value = typeof o !== "object" ? o : o.value;
                        const optionLabel = typeof o !== "object" ? o : o.label;
                        return (
                            <FormControlLabel
                                key={idx}
                                value={value}
                                control={<Radio color="primary"{...inputProps} />}
                                label={optionLabel}
                            />
                        );
                    })}
                </RadioGroup>
                <FormHelperText>{<Typography component='span' variant="body2" style={{ color: 'red' }} >{meta.touched && meta.error}</Typography>}</FormHelperText>
            </FormControl>
        </Grid>
    );
};

export const FormFileField = ({ name, accept, fileSizeLimit, description, multiple, label, required, fieldArea, condition, ...others }: Omit<HTMLProps<any>, "label" | "children" | "accept"> & FileFieldInterface) => {
    const [field, meta, helpers] = useField<File[] | Blob[] | undefined>({ name: name! });
    const fileInputRef = useRef<HTMLInputElement>(null);

    name === "form.personal.applicant_photo" &&
        console.log(name, { meta })

    const handleSetValue = useCallback(async (value: typeof field['value'] | string) => {
        await helpers.setValue(value as any, true)
        await helpers.setTouched(true, true)
    }, [helpers])

    const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e?.target?.files && e.target.files[0]) {
            const files = e.target.files;
            let _files: File[] = [];
            const _accept =
                typeof accept === "string"
                    ? accept.split(",").map((s) => s.replace(".", "").trim())
                    : accept;

            for (let x = 0; x < files.length; x++) {
                const file = files.item(x);
                file && _files.push(file)
            }

            if (!_accept) {
                handleSetValue(_files);
            }

            _files = _files.filter(file => file && _accept && _accept.some((v) => file.type.toLowerCase().includes(v)))

            if (_files.length > 0) {
                handleSetValue(_files);
            } else {
                handleSetValue("");
            }
        } else {
            handleSetValue("");
        }
    }, [helpers, accept]);

    const _accept = Array.isArray(accept) ? accept.map((f) => `.${f}`).join(", ") : accept;

    const visible = useIsVisible({ name, condition }, (isVisible) => !isVisible && handleSetValue(undefined))

    // console.log("field?.value ==>", field?.value)
    if (!visible) return null

    return (
        <Grid item container alignContent="flex-start" {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })} >
            <Typography color='textPrimary' >{label}{required && <span style={{ color: 'red' }}>*</span>}</Typography><div style={{ padding: '3px 0' }}> </div>
            <Grid item xs={12} component='label' htmlFor={`id-${name}`} style={{ cursor: 'pointer' }} >
                <input type="text" style={{ border: 0, width: 0, height: 0 }} value={field.value?.map?.(v => v.name)?.join(', ')} readOnly required={required} />
                {field.value?.map?.(v => v.name)?.join(', ') || <span style={{ border: '1px solid grey', }} >Upload File</span>}
            </Grid>
            <input ref={fileInputRef} style={{ display: 'none' }} type="file" id={`id-${name}`} onBlur={field.onBlur} accept={_accept} name={name} onChange={handleOnChange} multiple={multiple} />
            <div>
                <Typography variant="body2" style={{ color: 'red' }} >{meta.touched && meta.error}</Typography>
                {/* <ErrorMessage name={name!} render={(msg) => <Typography variant='body2' style={{ color: 'red', textAlign: "left", margin: '2px 0' }}>{msg}</Typography>} /> */}
                <Typography color='textPrimary' variant='caption' style={{ marginTop: '3px' }} >{description}</Typography>
            </div>
        </Grid>
    );
};

export const FormTextNote = ({ name, element, fieldArea, condition, }: Omit<HTMLProps<any>, "label"> & TextNoteFieldInterface) => {
    const visible = useIsVisible({ name, condition })

    if (!visible) return null

    return (
        <Grid item {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })}>
            {element && (
                <Typography variant="body1" component="div">
                    {element}
                </Typography>
            )}
        </Grid>
    );
};

type FormGroupFieldProps = HTMLProps<any> & FieldGroupInterface & {
    handleGenerateChildFields: () => JSX.Element[];
};
const Cgrid = styled(Grid)`
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    &:focus-within {
        border: 1px solid rgba(0, 0, 0);
    }
`
export const FormGroupField = ({ name, label, type, root, fieldGroup, fieldArea, condition, handleGenerateChildFields, notRequired, ...others }: FormGroupFieldProps) => {
    const visible = useIsVisible({ name, condition })

    if (!visible) return null

    return (
        <>
            <Cgrid item
                {...(typeof fieldArea === 'object' ? fieldArea : { xs: fieldArea })}
                style={{ padding: !root ? '10px' : undefined, paddingTop: '5px', border: root ? 0 : undefined, borderRadius: '2px' }}
            >
                <Typography component={Box} variant="body1" style={{ marginBottom: "4px" }}>
                    {label}
                </Typography>
                {root && <br />}
                <Grid container spacing={1} style={{}}>
                    {handleGenerateChildFields()}
                </Grid>
            </Cgrid>
            <div style={{ height: '20px', width: '100%' }} /* this grid is added for extra spacing */ />
        </>
    );
};


/** 
 * Created for form fields. This hook can use to make the field visible or hide based on the condition
 * ## params
 * - (name: string) This represents the name of the field.
 * Example name can be like ("name", "personal.name", "members[1].name")
 * 
 * - (condition?: FileFieldInterface['condition']) This represents the condition
 * that the field should be visible or hidden based on the condition. It expects
 * fieldName and value. FieldName is of the field which we want to check that if
 * the field has the value in it. Once the field has the value which we have passed
 * in the condition object then based on it the visible state will be true or false.
 * 
 * - (callback?: (visible: boolean) => void) This will be called when the condition field will be changed.
 */
export function useIsVisible({ name, condition }: { name: string, condition?: FileFieldInterface['condition'] }, callback?: (visible: boolean) => void) {
    const [visible, setVisible] = useState(true);
    if (condition) {
        const numArr = name?.match(/[\d]/g);
        const condFieldName = numArr
            ? replaceNwithValue(condition.fieldName, numArr)
            : condition.fieldName;
        const [condField] = useField("form." + condFieldName);
        useEffect(() => {
            const isIncluded = condition.value.includes(condField.value);
            setVisible(isIncluded);
            callback?.(isIncluded)
        }, [condField.value]);
    }
    return visible;
}


/**
 * 
 * @param param0 e = accepts change event of input element
 * @param param1 handleChange = accepts the onChange handler
 * @param param2 includs = accepts an array of characters that need to be include
 * @param param3 formatting = accepts boolean, if true than if will return "1,000" else "1000"
 *                      number formatting can only be possible if the include array is empty
 */
const numFrmt = new Intl.NumberFormat();
export const filterNumber = ({ e, handleChange, include = [], formating = false }: {
    e: React.ChangeEvent<HTMLInputElement>, handleChange: (e: React.ChangeEvent) => void, include?: string[], formating?: boolean,
}) => {
    const filterChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ...include];

    let value: number | string = e.target.value.split('').filter((chr) => filterChars.includes(chr)).join('');

    if (formating) {
        const _num = Number(value);
        if (isNaN(_num)) { value = _num }
        else { value = numFrmt.format(_num) }
    }

    e.target.value = value.toString();
    handleChange(e)
}


export interface FieldInterface {
    id?: string;
    name: string;
    type: "its-number" | "button" | "confirmation-checkbox" | "color" | "datetime-local" | "text-area" | "email" | "hidden" | "image" | "month" | "number" | "semi-number" | "password" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week";
    label: ReactNode
    notRequired?: boolean;
    value?: string; // default value
    // fieldArea?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12)[]
    fieldArea?: GridSize | Pick<GridTypeMap['props'], 'sm' | 'md' | 'lg' | 'xl' | 'xs'>
    /**
    * the key fieldName in condition is the name of the field which in present in the formik form
        and its value will be used to check with field name, if they both matches the field will be visible.
    */
    // condition: { [fieldName: string]: string }
    condition?: { fieldName: string, value: string[] }
    /** chakra InputProps */
    inputProps?: any,
}

export interface DateFieldInterface extends Omit<FieldInterface, 'type'> {
    type: "date"
}
// export interface ConfirmationCheckBoxFieldInterface extends Omit<FieldInterface, 'type'> {
//     type: "confirmation-checkbox"
// }

export interface ArrayFieldInterface extends Omit<FieldInterface, 'type' | 'label'> {
    type: "array-field"
    /**
     * only works if "type" is "array-field"
     */
    fieldArrayGroup: CombineFieldInterface[];
    /**
     * This field is used for main label of the array fieldgroup
     */
    label: ReactNode
    /**
     * this field is used as array item's label
     */
    itemLabel: ReactNode | ((p: { index: number }) => ReactNode)
    /**
     * this field is used as label for adding new item for eg: "+Add New Item"
     */
    labelForAddNewItem?: ReactNode
}

export interface FieldGroupInterface extends Omit<FieldInterface, 'type' | 'label'> {
    type: "fieldGroup"
    /**
     * only works if "type" is "fieldGroup"
     */
    fieldGroup: CombineFieldInterface[];
    label?: ReactNode
    /**
     * If this field is true, the border of fieldGroup will be removed.
     * This ensures that the fieldGroup is top or base level.
     */
    root?: boolean
}

export interface SelectFieldInterface extends Omit<FieldInterface, 'type'> {
    type: "select" | "checkbox"
    /**
     * only works if "type" is "select" | "checkbox"
     */
    options: { value: string, label: string }[] | string[];
}

export interface RadioFieldInterface extends Omit<SelectFieldInterface, 'type'> {
    type: "radio"
    /**
     * only works if "type" is "radio"
     */
    alignVertical?: boolean
}

export interface FileFieldInterface extends Omit<FieldInterface, 'type'> {
    type: "file"
    /**
     * only works if "type" is "file"
     */
    description?: ReactNode
    multiple?: boolean
    /**
     * - if there is a string array then it should be like ["pdf", "png", "JPEG"]
     * - if there is a string then it should be like ".pdf, .png, .JGP"
     */
    accept: string | string[]
    /** if there are multiple files, it will sum up all the files size and then compare with the limit */
    fileSizeLimit?: {
        /** size should be in bytes like: 1kb = 1000bytes */
        size: number,
        /** if file size exceeds this error will be shown */
        error: string
    }
}

export interface CustomFieldInputProps extends Pick<FieldInterface, 'condition' | 'notRequired' | 'label' | 'name' | 'value'> { onChange?: (v: any) => void, scope: FormGenerator }
export interface CustomFieldInterface extends Omit<FieldInterface, 'type'> {
    type: "custom";
    /**
    * only works if "type" is "custom"
    */
    CustomField: (p: CustomFieldInputProps) => any
    ignore?: boolean
}

export interface TextNoteFieldInterface extends Omit<FieldInterface, 'type' | 'value'> {
    type: "text-note";
    /**
    * only works if "type" is "text-note"
    */
    element: ReactNode
}

export type CombineFieldInterface = FileFieldInterface | DateFieldInterface | RadioFieldInterface | ArrayFieldInterface | TextNoteFieldInterface | CustomFieldInterface | FieldInterface | FieldGroupInterface | SelectFieldInterface


// Similarly, convert other components like FormRadioField, FormFileField, FormArrayField, etc.
function replaceNwithValue(str: string, values: (number | string)[]) {
    const regex = /\[n\]/g;
    return str.replace(regex, () => `[${values.shift()}]`);
}