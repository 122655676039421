import React, { FC } from 'react';
import * as s from './style';
import Header from '../Header';
import Footer from '../Footer';

export interface Props {

}


//////////////////////////////////  component ////////////////////////////////
const PageLayout: FC<Props> = ({ children }) => {
    return (
        <s.Wrap>
            <div className="__pagelayout_header" ><Header /></div>
            <Header slimHeader={true} /> {/* this header comes after page scroll down */}
            <div className="__pagelayout_main" > {children} </div>
            <div className="__pagelayout_footer" ><Footer /></div>
        </s.Wrap>
    )
};


export default PageLayout;
